import React, { useContext, useEffect, useState } from "react";
import {
    Button,
    Dialog,
    Card,
    CardBody,
    CardFooter,
    Typography,
    Spinner,
    Input
} from "@material-tailwind/react";
import { PhotoIcon } from "@heroicons/react/24/outline";
import { API_LINK } from "../../../apiConfig";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { BsTrash, BsX } from "react-icons/bs";

export const AddUpdateDeleteAdvertisement = ({ open, handleOpen }) => {
    const { authTokens } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [advertisement, setAdvertisement] = useState();

    const [redirectUrl, setRedirectUrl] = useState('')
    const [image, setImage] = useState(null);
    const [imageData, setImageData] = useState(null);
    const [mobileImage, setMobileImage] = useState(null);
    const [mobileImageData, setMobileImageData] = useState(null);

    useEffect(() => {
        setLoading(true);
        if (open) {
            axios.get(`${API_LINK}/advertisements`, {
                headers: {
                    Authorization: `Bearer ${authTokens?.accessToken}`
                }
            }).then(({ data }) => {
                setAdvertisement(data);
            }).catch((error) => {
                console.log(error);
            }).finally(() => {
                setLoading(false);
            });
        }
    }, [open]);

    const addAdvertisementHandler = () => {
        setLoading(true);
        const formData = new FormData();

        if (image && mobileImage) {
            formData.append('image', image);
            formData.append('image', mobileImage);
            formData.append('redirectUrl', redirectUrl);


            const requestConfig = {
                headers: {
                    Authorization: `Bearer ${authTokens?.accessToken}`,
                },
            };

            axios.post(`${API_LINK}/advertisements`, formData, requestConfig).then(({ data }) => {
                closeModalHandler();
            }).catch((error) => {
                console.log(error);
            }).finally(() => {
                setLoading(false);
            });

        }
    };

    const deleteAdvertisementHandler = () => {
        setLoading(true);
        if (advertisement) {
            const requestConfig = {
                headers: {
                    Authorization: `Bearer ${authTokens?.accessToken}`
                }
            };

            axios.delete(`${API_LINK}/advertisements/${advertisement?.id}`, requestConfig).then(({ data }) => {
                closeModalHandler();
            }).catch((error) => {
                console.log(error);
            }).finally(() => {
                setLoading(false);
            });
        }
    };

    const closeModalHandler = () => {
        handleOpen();
        setAdvertisement(null);

        setMobileImage(null);
        setMobileImageData(null);
        setImage(null);
        setImageData(null);

        setRedirectUrl('')
    };

    const handleImageChange = (e, isMobile = false) => {
        const newImage = e.target.files[0];
        if (isMobile) {
            setMobileImage(newImage);
            if (newImage) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    setMobileImageData(e.target.result);
                };
                reader.readAsDataURL(newImage);
            }
        } else {
            setImage(newImage);
            if (newImage) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    setImageData(e.target.result);
                };
                reader.readAsDataURL(newImage);
            }
        }
    };

    const handleRemoveImage = () => {
        // if (isMobile) {
        setMobileImage(null);
        setMobileImageData(null);
        // } else {
        setImage(null);
        setImageData(null);
        // }
        if (advertisement) {
            setAdvertisement({
                ...advertisement,
                imageUrlWeb: null,
                imageUrlMobile: null,
                redirectUrl: ''
            });
        }
    };


    return (
        <>
            <Dialog
                size="sm"
                open={open}
                handler={closeModalHandler}
                className="bg-transparent shadow-none"
            >
                {!loading ? (
                    <Card className="mx-auto w-full">
                        <CardBody className="flex flex-col gap-4">
                            <Typography variant="h4" color="blue-gray">
                                {advertisement ? "Modifier l'annonce publicitaire" : "Ajouter une annonce publicitaire"}
                            </Typography>

                            <div className="gap-4 w-full flex flex-row">
                                {/* Image pour le web */}
                                {(!image && !advertisement?.imageUrlWeb && !imageData) ? (
                                    <div className="flex items-center justify-center w-full">
                                        <label className="flex rounded-lg border-4 border-dashed w-full p-10 group text-center">
                                            <div className="h-full w-full text-center flex flex-col justify-center items-center">
                                                <PhotoIcon className="h-[120px] text-gray-200" />
                                                <span>Ajouter - Desktop</span>
                                            </div>
                                            <input type="file" className="hidden" onChange={(e) => handleImageChange(e, false)} />
                                        </label>
                                    </div>
                                ) : (
                                    <div className="flex justify-center items-center relative w-full">
                                        <div className="flex justify-center items-center relative w-full h-full">
                                            <img src={(advertisement?.imageUrlWeb && !imageData) ? `${API_LINK}/${advertisement?.imageUrlWeb}` : imageData} alt="advertisement web" className="rounded-none h-40" />
                                        </div>


                                    </div>
                                )}

                                {/* Image pour le mobile */}
                                {(!mobileImage && !advertisement?.imageUrlMobile && !mobileImageData) ? (
                                    <div className="flex items-center justify-center w-full">
                                        <label className="flex rounded-lg border-4 border-dashed w-full p-10 group text-center">
                                            <div className="h-full w-full text-center flex flex-col justify-center items-center">
                                                <PhotoIcon className="h-[120px] text-gray-200" />
                                                <span>Ajouter - Mobile</span>
                                            </div>
                                            <input type="file" className="hidden" onChange={(e) => handleImageChange(e, true)} />
                                        </label>
                                    </div>
                                ) : (
                                    <div className="flex justify-center items-center relative w-full">
                                        <div className="flex justify-center items-center relative w-full h-full">
                                            <img src={(advertisement?.imageUrlMobile && !mobileImageData) ? `${API_LINK}/${advertisement?.imageUrlMobile}` : mobileImageData} alt="advertisement mobile" className="rounded-none h-40" />
                                        </div>

                                    </div>
                                )}

                            </div>

                            <div className="w-full">
                                <Input className='rounded-none bg-white' label="Lien de redirection" type="text" value={(redirectUrl && advertisement?.redirectUrl) ? redirectUrl : (redirectUrl && !advertisement?.redirectUrl) ? redirectUrl : (!redirectUrl && advertisement?.redirectUrl) && advertisement?.redirectUrl} onChange={(e) => setRedirectUrl(e.target.value)} />
                            </div>

                            {((image || advertisement?.imageUrlWeb || imageData) || (mobileImage || advertisement?.imageUrlMobile || mobileImageData)) &&
                                <div className="flex flex-row items-center gap-4">
                                    <button className="flex justify-center items-center text-white w-full h-10 bg-primary rounded-none"
                                        onClick={() => handleRemoveImage()}
                                    >
                                        <BsX className="text-3xl" />
                                    </button>
                                    {advertisement && <button
                                        className="w-full h-10 bg-red-400 flex justify-center items-center text-white rounded-none"
                                        onClick={() => deleteAdvertisementHandler()}
                                    >
                                        <BsTrash className="text-xl" />
                                    </button>}
                                </div>
                            }


                        </CardBody>
                        <CardFooter className="pt-0">
                            <Button variant="filled" className="bg-primary rounded-none shadow-none" onClick={addAdvertisementHandler} fullWidth>
                                {advertisement ? "Modifier" : "Ajouter"}
                            </Button>
                        </CardFooter>
                    </Card>
                ) : (
                    <div className="flex justify-center items-center p-5">
                        <Spinner />
                    </div>
                )}
            </Dialog>
        </>
    );
};
