import React from 'react';
import { useCurrency } from '../../contexts/CurrencyContext';

const SelectCurrency = () => {
    const { currency, setCurrency } = useCurrency();

    return (
        <select className="mx-2 text-sm" value={currency} onChange={(e) => setCurrency(e.target.value)}>
            <option value="USD">USD</option>
            <option value="EUR">EUR</option>
            <option value="AED">AED</option>
            <option value="SAR">SAR</option>
        </select>
    )
}

export default SelectCurrency