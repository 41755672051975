import React, { useContext, useEffect, useRef, useState } from "react";
import { SidebarContext } from "../../contexts/SidebarContext";
import { CartContext } from "../../contexts/CartContext";
import { Link, NavLink } from "react-router-dom";
import { Collapse, Navbar, Typography, Select, Option } from "@material-tailwind/react";

import Logo from "../../img/logo.png";
import { BsBag, BsList } from "react-icons/bs";
import SelectLanguage from "../../components/home/SelectLanguage";
import SelectCurrency from "../../components/home/SelectCurrency";


const Header = () => {

  // header state
  const [isActive, setIsActive] = useState(false);
  const { isOpen, setIsOpen } = useContext(SidebarContext);
  const { itemAmount } = useContext(CartContext);

  const [openNav, setOpenNav] = React.useState(false);
  const collapseRef = useRef(null);

  // event listener
  useEffect(() => {
    window.addEventListener("scroll", () => {
      window.scrollY > 60 ? setIsActive(true) : setIsActive(false);
    });

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };


  });


  const handleClickOutside = (event) => {
    if (collapseRef.current && !collapseRef.current.contains(event.target)) {
      setOpenNav(false);
    }
  };


  useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false),
    );
  }, []);


  const navList = (
    <ul className="mb-4 mt-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6 text-primary">
      <NavLink to={`/`} onClick={() => setOpenNav(false)}>
        {({ isActive }) => (
          <Typography
            className={`${isActive && "bg-secondary"} border-primary p-2 font-medium text-sm hover:opacity-80`}
            variant="paragraph"
          >
            HOME
          </Typography>
        )}
      </NavLink>


      <NavLink to={`/shop`} onClick={() => setOpenNav(false)}>
        {({ isActive }) => (
          <Typography
            className={`${isActive && "bg-secondary"} border-primary p-2 font-medium text-sm hover:opacity-80`}
            variant="paragraph"
          >
            SHOP
          </Typography>
        )}
      </NavLink>

      <NavLink to={`/services`} onClick={() => setOpenNav(false)}>
        {({ isActive }) => (
          <Typography
            className={`${isActive && "bg-secondary"} border-primary p-2 font-medium text-sm hover:opacity-80`}
            variant="paragraph"
          >
            SERVICES
          </Typography>
        )}
      </NavLink>

      <NavLink to={`/contact`} onClick={() => setOpenNav(false)}>
        {({ isActive }) => (
          <Typography
            className={`${isActive && "bg-secondary"} border-primary p-2 font-medium text-sm hover:opacity-80`}
            variant="paragraph"
          >
            CONTACT
          </Typography>
        )}
      </NavLink>
    </ul>
  );

  return (
    <Navbar
      fullWidth
      blurred={false}
      className={`${isActive ? "py-2" : "py-4"
        } bg-white fixed w-full z-40 lg:px-8 transition-all rounded-none shadow-none border border-b-primary border-opacity-10`}
    >
      <div className="mx-auto flex items-center justify-between h-full">
        <Link to={"/"} className="flex items-center">
          <img src={Logo} className={`${isActive ? "sm:h-16 h-12" : "sm:h-20 h-16"}`} alt="" />
        </Link>

        <div className="hidden lg:block">{navList}</div>


        {/* cart */}
        <div className="flex items-center text-primary">

          <SelectCurrency />
          <SelectLanguage />

          <div className="mr-4 lg:hidden" onClick={() => setOpenNav(!openNav)}>
            <BsList className="text-3xl" />
          </div>

          <div
            onClick={() => setIsOpen(!isOpen)}
            className="cursor-pointer flex relative"
          >
            <BsBag className="text-2xl ml-2" />
            {itemAmount > 0 && <div className="bg-red-500 absolute -right-2 -bottom-2 text-[12px] w-[18px] h-[18px] text-white rounded-full flex justify-center items-center">
              {itemAmount}
            </div>}
          </div>
        </div>
      </div>

      <Collapse open={openNav} ref={collapseRef}>
        <div className="text-center">
          {navList}
        </div>
      </Collapse>
    </Navbar>
  );
};

export default Header;
