import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import { motion, useInView, useAnimation } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import { API_LINK } from '../../apiConfig';
import Product from '../../components/Product';
import { Tab, TabPanel, Tabs, TabsBody, TabsHeader, Typography } from '@material-tailwind/react';
import { BsSearch } from 'react-icons/bs';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useZone } from '../../contexts/ZoneContext';

const Parfum = () => {
    const { t } = useTranslation();

    const location = useLocation();
    const navigate = useNavigate();

    const containerControls = useAnimation()
    const refContainer = useRef(null);
    const isInViewContainer = useInView(refContainer);

    useEffect(() => {
        if (isInViewContainer) {
            containerControls.start("visible")
        }
    }, [isInViewContainer])

    const [isActive, setIsActive] = useState(false);
    const [products, setProducts] = useState([]);

    const { zone } = useZone();


    const searchParams = new URLSearchParams(location.search);
    const typeparfum = searchParams.get('type');
    const specificationparfum = searchParams.get('specification')

    const [type, setType] = useState(typeparfum || undefined);
    const [specification, setSpecification] = useState(specificationparfum || '50ml');

    const [sortOrder, setSortOrder] = useState();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            window.scrollY > 60 ? setIsActive(true) : setIsActive(false);
        });
    });

    useEffect(() => {
        axios.get(`${API_LINK}/products/search?category=parfum&type=${type}&specification=${specification}&priceOrder=${sortOrder}&zone=${zone}`).then(({ data }) => {
            setProducts(data);
        }).catch((error) => {
            console.log(error);
        })
    }, [type, specification, sortOrder,zone]);

    const handleTabSelect = (tabValue) => {
        setType(tabValue);
        setSpecification('50ml')
        navigate(`/parfum?type=${tabValue}&specification=50ml`);
        // setSpecification('');

    };

    const handleSpecificationSelect = (specValue) => {
        if (type) {
            navigate(`/parfum?type=${type}&specification=${specValue}`);
        } else {
            navigate(`/parfum?specification=${specValue}`);
        }
        setSpecification(specValue);
    };


    return (
        <div>
            <div
                className={`${isActive ? 'h-[72px]' : 'h-[88px]'} top-0 bg-white fixed w-full transition-all`}
            ></div>

            <div className='sm:mt-[110px] mt-[96px] text-info'>


                {!location.pathname.startsWith("/product/") && <div className='bg-secondary w-full flex justify-center rounded-none mt-5 p-3'>
                    <div className='flex sm:flex-row flex-col items-center text-primary'>
                        <NavLink to="/diffuser" className='text-center rounded-none w-40 hover:bg-white'>
                            {({ isActive }) => (
                                <Typography
                                    className={`${isActive && "border-b-2"} border-primary p-2 font-normal capitalize`}
                                    variant="paragraph"
                                >
                                    {t('diffusers')}
                                </Typography>
                            )}
                        </NavLink>
                        <NavLink to="/parfum" className='text-center rounded-none w-40 hover:bg-white'>
                            {({ isActive }) => (
                                <Typography
                                    className={`${isActive && "border-b-2"} border-primary p-2 font-normal capitalize`}
                                    variant="paragraph"
                                >
                                    {t('perfumes')}
                                </Typography>
                            )}
                        </NavLink>

                        <NavLink to="/candle-reed" className='text-center rounded-none w-40 hover:bg-white'>
                            {({ isActive }) => (
                                <Typography
                                    className={`${isActive && "border-b-2"} border-primary p-2 font-normal capitalize`}
                                    variant="paragraph"
                                >
                                    {t('candles&reed')}
                                </Typography>
                            )}
                        </NavLink>

                        <NavLink to="/promotion" className='text-center rounded-none w-40 hover:bg-white'>
                            {({ isActive }) => (
                                <Typography
                                    className={`${isActive && "border-b-2"} border-primary p-2 font-normal capitalize`}
                                    variant="paragraph"
                                >
                                    {t('promotions')}
                                </Typography>
                            )}
                        </NavLink>
                    </div>
                </div>}

                <div ref={refContainer}>
                    <motion.section
                        variants={{
                            hidden: { opacity: 0, y: 75 },
                            visible: { opacity: 1, y: 0 }
                        }}
                        initial='hidden'
                        animate={containerControls}
                        transition={{ duration: 0.5, delay: 0.25 }}
                        className="py-10"
                    >
                        <div className="sm:container mx-auto">
                            {/* le titre doit etre dynamique */}
                            <Typography className="mb-10 text-center uppercase tracking-wider sm:text-3xl text-2xl font-normal font-secondary">{t('choose-parfume')}</Typography>

                            <Tabs value={type}>
                                <TabsHeader className='bg-white rounded-none p-0 sm:w-1/3 max-w-full mx-auto gap-4 px-4' indicatorProps={{ className: 'shadow-none rounded-none bg-primary' }}>
                                    <Tab value={'oriental'} className={`${type !== 'oriental' ? 'bg-white border border-primary' : 'text-white'} p-2`} onClick={() => handleTabSelect('oriental')}>
                                        ORIENTAL
                                    </Tab>
                                    <Tab value={'fresh'} className={`${type !== 'fresh' ? 'bg-white border border-primary' : 'text-white'} p-2`} onClick={() => handleTabSelect('fresh')}>
                                        FRESH
                                    </Tab>
                                    <Tab value={'floral'} className={`${type !== 'floral' ? 'bg-white border border-primary' : 'text-white'} p-2`} onClick={() => handleTabSelect('floral')}>
                                        FLORAL
                                    </Tab>
                                </TabsHeader>
                                <TabsBody>
                                    <TabPanel value={'oriental'}>
                                        <div className='w-full flex flex-wrap sm:justify-between justify-center items-center'>

                                            <Tabs value={specification} >
                                                <TabsHeader className='bg-secondary rounded-none p-0 mx-auto mb-2' indicatorProps={{ className: 'shadow-none rounded-none bg-transparent' }}>
                                                    <Tab value={'50ml'} className={`transition-colors duration-700 ease-in-out ${specification === '50ml' && 'text-white bg-primary'} w-fit`} onClick={() => handleSpecificationSelect('50ml')}>
                                                        50 ml
                                                    </Tab>
                                                    <Tab value={'250ml'} className={`transition-colors duration-700 ease-in-out ${specification === '250ml' && 'text-white bg-primary'} w-fit`} onClick={() => handleSpecificationSelect('250ml')}>
                                                        250 ml
                                                    </Tab>
                                                    <Tab value={'400ml'} className={`transition-colors duration-700 ease-in-out ${specification === '400ml' && 'text-white bg-primary'} w-fit`} onClick={() => handleSpecificationSelect('400ml')}>
                                                        400 ml
                                                    </Tab>
                                                </TabsHeader>
                                            </Tabs>

                                            <select value={sortOrder} className={`px-2 py-1 border placeholder:font-light bg-white border-gray-400`} onChange={(e) => setSortOrder(e.target.value)}>
                                                <option value='' disabled selected>{t('sort')}</option>
                                                <option value="asc">{t('from-cheapest')}</option>
                                                <option value="desc">{t('from-expensive')}</option>
                                            </select>
                                        </div>
                                        {products.length !== 0 ? (<div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 md:gap-[30px] lg:gap-[30px] gap-[14px] max-w-sm mx-auto md:max-w-none mt-6">
                                            {products.map((product) => {
                                                return (
                                                    <Product product={product} key={product.id} />
                                                );
                                            })}
                                        </div>) : (
                                            <div className='flex flex-col justify-center items-center text-info'>
                                                <BsSearch size={150} className='text-gray-200 m-5' />
                                                <Typography variant='h4' className='text-center'>
                                                    {t('search-not-found')}
                                                </Typography>
                                            </div>)}
                                    </TabPanel>
                                    <TabPanel value={'fresh'}>
                                        <div className='w-full flex flex-wrap sm:justify-between justify-center items-center'>
                                            <Tabs value={specification} >
                                                <TabsHeader className='bg-secondary rounded-none p-0 mx-auto mb-2' indicatorProps={{ className: 'shadow-none rounded-none bg-transparent' }}>
                                                    <Tab value={'50ml'} className={`transition-colors duration-700 ease-in-out ${specification === '50ml' && 'text-white bg-primary'} w-fit`} onClick={() => handleSpecificationSelect('50ml')}>
                                                        50 ml
                                                    </Tab>
                                                    <Tab value={'250ml'} className={`transition-colors duration-700 ease-in-out ${specification === '250ml' && 'text-white bg-primary'} w-fit`} onClick={() => handleSpecificationSelect('250ml')}>
                                                        250 ml
                                                    </Tab>
                                                    <Tab value={'400ml'} className={`transition-colors duration-700 ease-in-out ${specification === '400ml' && 'text-white bg-primary'} w-fit`} onClick={() => handleSpecificationSelect('400ml')}>
                                                        400 ml
                                                    </Tab>
                                                </TabsHeader>
                                            </Tabs>

                                            <select value={sortOrder} className={`px-2 py-1 border placeholder:font-light bg-white border-gray-400`} onChange={(e) => setSortOrder(e.target.value)}>
                                                <option value='' disabled selected>{t('sort')}</option>
                                                <option value="asc">{t('from-cheapest')}</option>
                                                <option value="desc">{t('from-expensive')}</option>
                                            </select>
                                        </div>
                                        {products.length !== 0 ? (<div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 md:gap-[30px] lg:gap-[30px] gap-[14px] max-w-sm mx-auto md:max-w-none mt-6">
                                            {products.map((product) => {
                                                return (
                                                    <Product product={product} key={product.id} />
                                                );
                                            })}
                                        </div>) : (
                                            <div className='flex flex-col justify-center items-center text-info'>
                                                <BsSearch size={150} className='text-gray-200 m-5' />
                                                <Typography variant='h4' className='text-center'>
                                                    {t('search-not-found')}
                                                </Typography>
                                            </div>)}
                                    </TabPanel>
                                    <TabPanel value={'floral'}>
                                        <div className='w-full flex flex-wrap sm:justify-between justify-center items-center'>
                                            <Tabs value={specification} >
                                                <TabsHeader className='bg-secondary rounded-none p-0 mx-auto mb-2' indicatorProps={{ className: 'shadow-none rounded-none bg-transparent' }}>
                                                    <Tab value={'50ml'} className={`transition-colors duration-700 ease-in-out ${specification === '50ml' && 'text-white bg-primary'} w-fit`} onClick={() => handleSpecificationSelect('50ml')}>
                                                        50 ml
                                                    </Tab>
                                                    <Tab value={'250ml'} className={`transition-colors duration-700 ease-in-out ${specification === '250ml' && 'text-white bg-primary'} w-fit`} onClick={() => handleSpecificationSelect('250ml')}>
                                                        250 ml
                                                    </Tab>
                                                    <Tab value={'400ml'} className={`transition-colors duration-700 ease-in-out ${specification === '400ml' && 'text-white bg-primary'} w-fit`} onClick={() => handleSpecificationSelect('400ml')}>
                                                        400 ml
                                                    </Tab>
                                                </TabsHeader>
                                            </Tabs>

                                            <select value={sortOrder} className={`px-2 py-1 border placeholder:font-light bg-white border-gray-400`} onChange={(e) => setSortOrder(e.target.value)}>
                                                <option value='' disabled selected>{t('sort')}</option>
                                                <option value="asc">{t('from-cheapest')}</option>
                                                <option value="desc">{t('from-expensive')}</option>
                                            </select>
                                        </div>
                                        {products.length !== 0 ? (<div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 md:gap-[30px] lg:gap-[30px] gap-[14px] max-w-sm mx-auto md:max-w-none mt-6">
                                            {products.map((product) => {
                                                return (
                                                    <Product product={product} key={product.id} />
                                                );
                                            })}
                                        </div>) : (
                                            <div className='flex flex-col justify-center items-center text-info'>
                                                <BsSearch size={150} className='text-gray-200 m-5' />
                                                <Typography variant='h4' className='text-center'>
                                                    {t('search-not-found')}
                                                </Typography>
                                            </div>)}
                                    </TabPanel>



                                    {type !== 'oriental' && type !== 'fresh' && type !== 'floral' && (
                                        <TabPanel value={type}>
                                            <div className='w-full flex flex-wrap sm:justify-between justify-center items-center'>
                                                <Tabs value={specification} >
                                                    <TabsHeader className='bg-secondary rounded-none p-0 mx-auto mb-2' indicatorProps={{ className: 'shadow-none rounded-none bg-transparent' }}>
                                                        <Tab value={'50ml'} className={`transition-colors duration-700 ease-in-out ${specification === '50ml' && 'text-white bg-primary'} w-fit`} onClick={() => handleSpecificationSelect('50ml')}>
                                                            50 ml
                                                        </Tab>
                                                        <Tab value={'250ml'} className={`transition-colors duration-700 ease-in-out ${specification === '250ml' && 'text-white bg-primary'} w-fit`} onClick={() => handleSpecificationSelect('250ml')}>
                                                            250 ml
                                                        </Tab>
                                                        <Tab value={'400ml'} className={`transition-colors duration-700 ease-in-out ${specification === '400ml' && 'text-white bg-primary'} w-fit`} onClick={() => handleSpecificationSelect('400ml')}>
                                                            400 ml
                                                        </Tab>
                                                    </TabsHeader>
                                                </Tabs>

                                                <select value={sortOrder} className={`px-2 py-1 border placeholder:font-light bg-white border-gray-400`} onChange={(e) => setSortOrder(e.target.value)}>
                                                    <option value='' disabled selected>{t('sort')}</option>
                                                    <option value="asc">{t('from-cheapest')}</option>
                                                    <option value="desc">{t('from-expensive')}</option>
                                                </select>
                                            </div>
                                            {products.length !== 0 ? (<div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 md:gap-[30px] lg:gap-[30px] gap-[14px] max-w-sm mx-auto md:max-w-none mt-6">
                                                {products.map((product) => {
                                                    return (
                                                        <Product product={product} key={product.id} />
                                                    );
                                                })}
                                            </div>) : (
                                                <div className='flex flex-col justify-center items-center text-info'>
                                                    <BsSearch size={150} className='text-gray-200 m-5' />
                                                    <Typography variant='h4' className='text-center'>
                                                        {t('search-not-found')}
                                                    </Typography>
                                                </div>)}
                                        </TabPanel>
                                    )}


                                </TabsBody>
                            </Tabs>
                        </div>
                    </motion.section>
                </div>
            </div>
        </div>
    )
}

export default Parfum