import React, { useContext, useState } from "react";
import {
    Button,
    Dialog,
    Card,
    CardBody,
    CardFooter,
    Typography,
    Spinner,
    Select,
    Option,
} from "@material-tailwind/react";
import { t } from "i18next";


export const SelectZone = ({ open, handleOpen, setZone, zones, zone }) => {
    const [selectedZone, setSelectedZone] = useState(zone)

    const selectZoneHandler = () => {
        if (selectedZone) {
            setZone(selectedZone);
            closeModalHandler();
        }
    }


    const closeModalHandler = () => {
        handleOpen();
    }

    return (
        <>
            <Dialog
                size="sm"
                open={open}
                // handler={closeModalHandler}
                className="bg-transparent shadow-none"
            >
                <Card className="mx-auto w-full">
                    <CardBody className="flex flex-col gap-4">
                        <Typography variant="h4" color="blue-gray">
                            {t('select-area')}
                        </Typography>
                        <div className="w-full flex justify-center">
                            <Select
                                label={t('geographic-area')}
                                value={selectedZone}
                                onChange={(e) => setSelectedZone(e)}
                            >
                                {zones.map((zone) => <Option key={zone.zone} value={zone.zone} className="capitalize">{t(zone.zone)}</Option>)}
                            </Select>
                        </div>
                    </CardBody>
                    <CardFooter className="pt-0">
                        <Button variant="filled" className="bg-primary text-white shadow-none rounded-none" onClick={() => selectZoneHandler()} fullWidth>
                            {t('select')}
                        </Button>
                    </CardFooter>
                </Card>
            </Dialog>
        </>
    );
}