import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Typography,
} from "@material-tailwind/react";

const StatisticsCard = ({ color, icon, title, value, footer }) => {
    return (
        <Card className="bg-secondary shadow-none text-primary border border-gray-400 rounded-none">
            <CardHeader
                variant="filled"
                floated={false}
                shadow={false}
                className="absolute grid h-12 w-12 place-items-center bg-primary rounded-none"
            >
                {icon}
            </CardHeader>
            <CardBody className="p-4 text-right">
                <Typography variant="small" className="font-normal">
                    {title}
                </Typography>
                <Typography variant="h4">
                    {value}
                </Typography>
            </CardBody>
            {footer && (
                <CardFooter className="border-t border-primary p-4">
                    {footer}
                </CardFooter>
            )}
        </Card>
    );
}



export default StatisticsCard;
