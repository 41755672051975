import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import frLang from './locales/fr.json';
import enLang from './locales/en.json';
import arLang from './locales/ar.json';


const resources = {
  en: {
    translation: enLang
  },
  fr: {
    translation: frLang
  },
  ar: {
    translation: arLang
  }
};

i18n
  .use(initReactI18next) 
  .init({
    resources,
    lng: "en", 
    interpolation: {
      escapeValue: false
    }
  });

  export default i18n;