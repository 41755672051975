import React, { useContext, useState } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Input,
    Checkbox,
    Button,
} from "@material-tailwind/react";
import axios from "axios";
import { API_LINK } from "../../apiConfig";

import Logo from "../../img/logo.png";


const Login = () => {
    const { login } = useContext(AuthContext);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(true);

    const authenticate = () => {
        if (email && password) {
            axios.post(`${API_LINK}/auth/login`, {
                email,
                password
            }).then(({ data }) => {
                login(data, rememberMe);
            }).catch((error) => {
                alert(error);
            })
        } else {
            alert("veuillez remplir tous les champs");
        }
    }

    return (
        <div className="w-full h-screen flex justify-center items-center">
            <Card className="sm:w-96 w-80 bg-secondary shadow-none mt-24 rounded-none">
                <CardHeader
                    floated={false}
                    className="flex justify-center items-center mb-4 h-20 place-items-center shadow-none rounded-none"
                >
                    <div className="w-36">
                        <img src={Logo} alt="" />
                    </div>
                </CardHeader>
                <CardBody className="flex flex-col gap-4" >
                    <Input label="Adresse email" type="email" size="lg" className="bg-gray-50" onChange={(e) => setEmail(e.target.value)} />
                    <Input label="Mot de passe" type="password" size="lg" className="bg-gray-50" onChange={(e) => setPassword(e.target.value)} />
                    <div className="-ml-2.5">
                        <Checkbox color="gray" className="bg-gray-50" label="Se souvenir de moi" defaultChecked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)} />
                    </div>
                </CardBody>
                <CardFooter className="pt-0">
                    <Button onClick={() => authenticate()} className="bg-primary rounded-none" fullWidth>
                        Connexion
                    </Button>
                </CardFooter>
            </Card>
        </div>
    );
};

export default Login;
