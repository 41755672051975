import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';

import { Link, useNavigate } from "react-router-dom";

import { IoMdArrowForward, IoMdClose } from "react-icons/io";
import { FiTrash2 } from "react-icons/fi";

import CartItem from "../../components/CartItem";
import { SidebarContext } from "../../contexts/SidebarContext";
import { CartContext } from "../../contexts/CartContext";

import { useCurrency } from "../../contexts/CurrencyContext";
import fx from 'money';

const Sidebar = () => {
  const { t } = useTranslation();
  const { currency, rates } = useCurrency();


  const { isOpen, handleClose } = useContext(SidebarContext);
  const { cart, clearCart, itemAmount, total } = useContext(CartContext);
  const SidebarRef = useRef(null);

  const [convertedTotal, setConvertedTotal] = useState(total);

  const navigate = useNavigate();

  useEffect(() => {
    if (cart.length === 0) {
      handleClose();
    }
  }, [cart])


  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [])

  fx.base = 'USD';

  useEffect(() => {
    if (Object.keys(rates).length !== 0 && total !== 0) {
      fx.rates = rates;
      if (currency !== 'USD') {
        const converted = fx.convert(total, { from: fx.base, to: currency });
        setConvertedTotal(converted);
      } else {
        setConvertedTotal(total);
      }
    } else {
      setConvertedTotal(total); 
    }
  }, [currency, rates, total]);


  const handleClickOutside = (event) => {
    if (SidebarRef.current && !SidebarRef.current.contains(event.target)) {
      handleClose();
    }
  };

  const continuePurchasing = () => {
    if (cart.length !== 0) {
      if (cart[cart.length - 1].category === 'parfum') {
        navigate('/parfum')
      } else if (cart[cart.length - 1].category === 'diffuseur') {
        navigate('/diffuser')
      }
    }
  }

  return (
    <div
      ref={SidebarRef}
      className={`${isOpen ? "right-0" : "-right-full"
        } "w-full bg-white fixed top-0 h-full flex flex-col flex-1 shadow-2xl md:w-[35vw] lg:w-[40vw] xl:max-w-[30vw] transition-all duration-300 z-50 px-4 lg:px-[35px]"`}
    >
      <div className="flex items-center justify-between py-6 border-b">
        <div className="uppercase text-sm font-semibold">{t('cart')}({itemAmount})</div>
        <div
          onClick={handleClose}
          className="cursor-poniter w-8 h-8 flex justify-center items-center"
        >
          <IoMdClose className="text-2xl cursor-pointer" />
        </div>
      </div>
      <div className="flex flex-col gap-y-2 flex-1 overflow-y-auto overflow-x-hidden border-b">
        {cart.map((item) => {
          return <CartItem item={item} key={item.id} />;
        })}
      </div>
      <div className="flex flex-col gap-y-3  my-4">
        {cart.length !== 0 && <div className="flex w-full justify-between items-center">
          {/* total */}
          <div className="font-semibold">
            <span className="mr-2 capitalize">{t('total')}:</span>{" "}
            {parseFloat(convertedTotal).toFixed(2)} {" "} {currency}
          </div>
          {/* clear cart icon */}
          <div
            onClick={clearCart}
            className="cursor-pointer py-4 bg-red-500 text-white w-12 h-12 flex justify-center items-center text-xl"
          >
            <FiTrash2 />
          </div>
        </div>}
        <button
          onClick={() => { handleClose(); continuePurchasing(); }}
          // to={"/shopping-cart"}
          className="bg-gray-200 flex p-3 justify-center items-center text-primary w-full font-medium"
        >
          {t('continue-shopping')}
        </button>
        <Link
          onClick={handleClose}
          // to={"/place-order"}
          to={"/shopping-cart"}
          className="bg-primary flex p-3 justify-center items-center text-white w-full font-medium"
        >
          {t('finalise-order')}
        </Link>
      </div>
    </div>
  );
};

export default Sidebar;
