import React, { useContext, useEffect, useState } from 'react'
import StatisticsCard from '../../components/dashboard/StatisticsCard'
import StatisticsChart from '../../components/dashboard/StatisticsChart';

import chartsConfig from '../../configs/ChartsConfig';

import { FaShippingFast, FaClock, FaShoppingBag } from "react-icons/fa";

import { Typography } from '@material-tailwind/react';
import axios from 'axios';
import { API_LINK } from '../../apiConfig';
import { AuthContext } from '../../contexts/AuthContext';
import { format, parseISO } from 'date-fns';
import { fr } from 'date-fns/locale';

const orderChart = {
  type: "bar",
  height: 220,
  series: [
    {
      name: "Orders",
      data: [],
    },
  ],
  options: {
    ...chartsConfig,
    colors: "#000000",
    plotOptions: {
      bar: {
        columnWidth: "16%",
        borderRadius: 5,
      },
    },
    xaxis: {
      ...chartsConfig.xaxis,
      categories: [],
    },
  },
};

const canceledOrderChart = {
  type: "line",
  height: 220,
  series: [
    {
      name: "Canceled Orders",
      data: [],
    },
  ],
  options: {
    ...chartsConfig,
    colors: ["#000000"],
    stroke: {
      lineCap: "round",
    },
    markers: {
      size: 5,
    },
    xaxis: {
      ...chartsConfig.xaxis,
      categories: [],
    },
  },
};



const statisticsChartsData = [
  {
    title: "Commandes livrées",
    description: "Pour les 7 derniers jours",
    footer: "mis à jour aujourd'hui",
    chart: orderChart,
  },
  {
    title: "Commandes annulées",
    description: "Pour les 7 derniers jours",
    footer: "mis à jour aujourd'hui",
    chart: canceledOrderChart,
  },
];


const Statistics = () => {
  const { authTokens } = useContext(AuthContext);

  const [statistics, setStatistics] = useState();

  const formatDate = (dateString) => {
    const date = parseISO(dateString); // Parse the date string
    return format(date, 'EEEE', { locale: fr }); // Format the date as "jeudi", "vendredi", etc.
  };

  useEffect(() => {
    axios.get(`${API_LINK}/statistics/statistics-cards`, {
      headers: {
        Authorization: `Bearer ${authTokens?.accessToken}`
      }
    }).then(({ data }) => {
      const lastWeekOrdersFormattedData = {
        number_of_orders: data.lastWeekOrders.number_of_orders,
        order_dates: data.lastWeekOrders.order_dates.map((dateString) =>
          formatDate(dateString)
        ),
      };

      const lastWeekCanceledOrdersFormattedData = {
        number_of_canceled_orders: data.lastWeekCanceledOrders.number_of_canceled_orders,
        order_dates: data.lastWeekCanceledOrders.order_dates.map((dateString) =>
          formatDate(dateString)
        ),
      };

      // Update ordersChart with new data
      const updatedOrderChart = {
        ...orderChart,
        series: [
          {
            name: "Orders",
            data: data.lastWeekOrders.number_of_orders,
          },
        ],
        options: {
          ...orderChart.options,
          xaxis: {
            ...orderChart.options.xaxis,
            categories: lastWeekOrdersFormattedData.order_dates,
          },
        },
      };


      // Update canceledOrdersChart with new data
      const updatedCanceledOrderChart = {
        ...canceledOrderChart,
        series: [
          {
            name: "Canceled Orders",
            data: lastWeekCanceledOrdersFormattedData.number_of_canceled_orders,
          },
        ],
        options: {
          ...canceledOrderChart.options,
          xaxis: {
            ...canceledOrderChart.options.xaxis,
            categories: lastWeekCanceledOrdersFormattedData.order_dates,
          },
        },
      };

      setStatistics({
        ...data,
        lastWeekOrders: lastWeekOrdersFormattedData,
        lastWeekCanceledOrders: lastWeekCanceledOrdersFormattedData,
        updatedOrderChart,
        updatedCanceledOrderChart
      });
    }).catch((error) => {
      console.log(error);
    })
  }, [])


  return (
    <div className="mt-12">
      <div className="mb-12 grid gap-y-10 gap-x-6 md:grid-cols-2 lg:grid-cols-3">
        <StatisticsCard
          title={"Total produits"}
          value={statistics?.countProduct}
          icon={React.createElement(FaShoppingBag, {
            className: "w-6 h-6 text-white",
          })}
          footer={
            <Typography className="font-normal">
              Nombre total de produits
            </Typography>
          }
        />
        <StatisticsCard
          title={"En attente"}
          value={statistics?.countPendingOrders}
          icon={React.createElement(FaClock, {
            className: "w-6 h-6 text-white",
          })}
          footer={
            <Typography className="font-normal">
              Commandes en attente
            </Typography>
          }
        />
        <StatisticsCard
          title={"Livrées"}
          value={statistics?.countDeliveredOrders}
          icon={React.createElement(FaShippingFast, {
            className: "w-6 h-6 text-white",
          })}
          footer={
            <Typography className="font-normal">
              Commandes livrées
            </Typography>
          }
        />
      </div>


      <div className="mb-6 grid grid-cols-1 gap-y-12 gap-x-6 md:grid-cols-2 xl:grid-cols-3">
        {statisticsChartsData.map((props, index) => (
          <StatisticsChart
            key={props.title}
            {...props}
            chart={
              index === 0
                ? statistics?.updatedOrderChart || props.chart
                : index === 1
                  ? statistics?.updatedCanceledOrderChart || props.chart
                  : props.chart
            }
            footer={
              <Typography
                variant="small"
                className="flex items-center font-normal text-primary"
              >
                <FaClock strokeWidth={2} className="h-4 w-4" />
                &nbsp;{props.footer}
              </Typography>
            }
          />
        ))}
      </div>

    </div>
  )
}

export default Statistics