// import React, { createContext, useState, useEffect, useContext } from 'react';
// import { API_LINK } from '../apiConfig';
// import axios from 'axios';
// import Loading from '../pages/auth/Loading';

// // Créer le contexte
// const ZoneContext = createContext();

// // Fonction pour accéder au contexte
// export const useZone = () => {
//     return useContext(ZoneContext);
// };

// // Composant Provider pour encapsuler l'application
// export const ZoneProvider = ({ children }) => {
//     // const [zone, setZone] = useState(null); // état de la zone
//     const [zone, setZone] = useState(null)
//     const [zones, setZones] = useState([]); // Liste des zones disponibles
//     const [loading, setLoading] = useState(true); // état de chargement
//     const [error, setError] = useState(null); // gestion des erreurs

//     // Simuler l'appel à l'API pour récupérer les zones (ou une API réelle)
//     useEffect(() => {
//         const checkZone = async () => {
//             try {
//                 const response = await axios.get(`${API_LINK}/shippingprices/zones`);
//                 setZones(response.data);

//                 // const storedZone = localStorage.getItem('zone'); // Exemple avec localStorage
//                 // if (storedZone) {
//                 //     setZone(storedZone); // Si la zone est dans localStorage, la définir
//                 // } else {
//                 //     setZone('Zone par défaut');
//                 //     localStorage.setItem('zone', 'Zone par défaut');
//                 // }
//             } catch (err) {
//                 setError(err.message);
//             } finally {
//                 setLoading(false);
//             }
//         };

//         checkZone();
//     }, []);




//     // Valeurs à fournir dans le contexte
//     const value = {
//         zone,
//         setZone,
//         zones,
//         loading,
//         error,
//     };

//     return (
//         <ZoneContext.Provider value={value}>
//             {loading ? <Loading /> : children}
//         </ZoneContext.Provider>
//     );
// };


import React, { createContext, useState, useEffect, useContext } from 'react';
import { API_LINK } from '../apiConfig';
import axios from 'axios';
import Loading from '../pages/auth/Loading';

// Créer le contexte
const ZoneContext = createContext();

// Fonction pour accéder au contexte
export const useZone = () => {
    return useContext(ZoneContext);
};

// Composant Provider pour encapsuler l'application
export const ZoneProvider = ({ children }) => {
    const [zone, setZone] = useState(() =>
        localStorage.getItem("zone")
            ? localStorage.getItem("zone")
            : null)
    const [zones, setZones] = useState([]); // Liste des zones disponibles
    const [loading, setLoading] = useState(true); // état de chargement
    const [error, setError] = useState(null); // gestion des erreurs

    // Simuler l'appel à l'API pour récupérer les zones (ou une API réelle)
    useEffect(() => {
        const checkZone = async () => {
            try {

                const response = await axios.get(`${API_LINK}/shippingprices/zones`);
                setZones(response.data);


                // Vérifier si une zone est stockée dans localStorage
                const storedZone = localStorage.getItem('zone');
                if (storedZone) {
                    setZone(storedZone); // Si la zone est dans localStorage, la définir
                    setLoading(false); // On n'a plus besoin de charger les zones à partir de l'API
                } else {
                    // Si aucune zone n'est stockée, faire la requête pour récupérer les zones
                    // const response = await axios.get(`${API_LINK}/shippingprices/zones`);
                    // setZones(response.data);
                    // setLoading(false);
                    // // Choisir une zone par défaut (par exemple, la première zone de la liste)
                    // const defaultZone = response.data.length > 0 ? response.data[0] : 'Zone par défaut';
                    // setZone(defaultZone);
                    // localStorage.setItem('zone', defaultZone); // Sauvegarder la zone par défaut dans localStorage
                }


                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false); // En cas d'erreur, on arrête le chargement
            }
        };

        checkZone();
    }, []);

    // Fonction pour changer la zone et la sauvegarder dans localStorage
    const updateZone = (newZone) => {
        setZone(newZone);
        localStorage.setItem('zone', newZone); // Enregistrer la nouvelle zone dans localStorage
    };

    // Valeurs à fournir dans le contexte
    const value = {
        zone,
        setZone: updateZone, // Utiliser la fonction updateZone pour modifier la zone
        zones,
        loading,
        error,
    };

    return (
        <ZoneContext.Provider value={value}>
            {loading ? <Loading /> : children}
        </ZoneContext.Provider>
    );
};
