import { Typography } from '@material-tailwind/react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BsCartX } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';

function RefusedPayment() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            navigate("/place-order");
        }, 3000);
    }, [])

    return (
        <div className='w-full h-screen flex justify-center items-center'>
            <div className="grid place-items-center gap-4 p-16">
                <BsCartX size={100} className='text-primary' />
                <Typography variant="h4" className='text-primary w-2/3 text-center'>
                    {t('order-refused')}
                </Typography>
            </div>
        </div>
    )
}

export default RefusedPayment