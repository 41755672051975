import { Typography } from "@material-tailwind/react";
import React from "react";
import { FaShippingFast, FaHandHoldingUsd, FaCommentDots, FaWhatsapp } from "react-icons/fa";
import { FiPhoneCall, FiInstagram, FiLinkedin, FiMail, FiMapPin, FiAward, FiCreditCard, FiTruck } from "react-icons/fi";

import Logo from "../../img/logo.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";



const Footer = () => {
    const { t } = useTranslation();

    return (
        <footer className="bg-secondary pt-8 pb-4 text-primary">
            <div className="container mx-auto">


                <div className="grid sm:grid-cols-2 md:grid-cols-6 grid-cols-1 gap-4 py-8">
                    <Link to={"/"} className="flex items-center pr-4 md:col-span-2 col-span-1">
                        <img src={Logo} alt="logo footer" className="h-20" />
                    </Link>
                    <div className="md:col-span-4 grid md:grid-cols-3 grid-cols-1">
                        <div className="flex flex-row justify-start items-center sm:mb-0 mb-4">
                            <FiAward size={36} />
                            <div className="ml-4">
                                <Typography className="text-sm font-semibold">
                                    {t('satisfaction-guaranteed')}.
                                </Typography>
                                <Typography className="text-sm font-normal">
                                    {t('high-quantify')}
                                </Typography>
                            </div>
                        </div>

                        <div className="flex flex-row justify-start items-center sm:mb-0 mb-4">
                            <FiTruck size={36} />
                            <div className="ml-4">
                                <Typography className="text-sm font-semibold">
                                    {t('fast-shipping')}.
                                </Typography>
                                <Typography className="text-sm font-normal">
                                    {t('shipping-24-48h')}.
                                </Typography>
                            </div>
                        </div>

                        <div className="flex flex-row justify-start items-center sm:mb-0 mb-4">
                            <FiCreditCard size={36} />
                            <div className="ml-4">
                                <Typography className="text-sm font-semibold">
                                    {t('card-payment')}.
                                </Typography>
                                <Typography className="text-sm font-normal">
                                    {t('instant-payment')}.
                                </Typography>
                            </div>
                        </div>
                    </div>
                </div>



                <div className="grid sm:grid-cols-4 md:grid-cols-6 grid-cols-1 gap-4 pb-8">


                    <div className="flex flex-col items-start sm:col-span-2 md:col-span-2 col-span-1 sm:mb-0 mb-4">
                        <div className="flex items-center mb-1">
                            <FiPhoneCall className="mr-3 text-lg" />
                            <span className="font-normal text-sm"> +971 558921934</span>
                        </div>
                        <div className="flex items-center mb-1">
                            <FiMail className="mr-3 text-lg" />
                            <span className="font-normal text-sm">contact@secretscenta.com</span>
                        </div>
                        <a className="flex items-center mb-1" href="https://www.instagram.com/secretscenta_uae/" target="_blank">
                            <FiInstagram className="mr-3 text-lg" />
                            <span className="font-normal text-sm">secretscenta_uae</span>
                        </a>

                        <a className="flex items-center mb-1" href="https://www.google.com/maps/place/UNIGROVE+Business+Center/@25.2527312,55.3388341,17z/data=!3m1!4b1!4m6!3m5!1s0x3e5f5d0041fd950d:0xd0ad24bc30823d79!8m2!3d25.2527312!4d55.3388341!16s%2Fg%2F11vsc_9spx?entry=ttu&g_ep=EgoyMDI0MTExMi4wIKXMDSoASAFQAw%3D%3D" target="_blank">
                            <FiMapPin className="mr-3 text-xl" />
                            <span className="font-normal text-sm lowercase">UNIGROVE BUSINESS CENTER L.L.C El garhoud</span>
                        </a>

                        <a className="flex items-center mb-1" href="https://wa.me/971558921934" target="_blank">
                            <FaWhatsapp className="mr-3 text-xl" /> {/* WhatsApp Icon */}
                            <span className="font-normal text-sm">Contact us on WhatsApp</span>
                        </a>
                    </div>


                    <div className="flex flex-col sm:col-span-2  md:col-span-1 col-span-1 items-start sm:mb-0 mb-4">
                        <Typography variant="paragraph" className="font-medium mb-2 uppercase">
                            {t('links')}
                        </Typography>

                        <Link to={`/`}>
                            <Typography
                                className={`font-normal text-sm`}
                            >
                                Home
                            </Typography>
                        </Link>

                        <Link to={`/shop`}>
                            <Typography
                                className={`font-normal text-sm`}
                            >
                                Shop
                            </Typography>
                        </Link>

                        <Link to={`/services`}>
                            <Typography
                                className={`font-normal text-sm`}
                            >
                                Services
                            </Typography>
                        </Link>

                        <Link to={`/contact`}>
                            <Typography
                                className={`font-normal text-sm`}
                            >
                                Contact
                            </Typography>
                        </Link>

                    </div>


                    <div className="flex flex-col items-start sm:col-span-2  md:col-span-1 col-span-1 sm:mb-0 mb-4">
                        <Typography variant="paragraph" className="font-medium mb-2">
                            SHOP
                        </Typography>

                        <Link to={`/diffuser`}>
                            <Typography
                                className={`font-normal text-sm`}
                            >
                                {t('diffusers')}
                            </Typography>
                        </Link>

                        <Link to={`/parfum`}>
                            <Typography
                                className={`font-normal text-sm`}
                            >
                                {t('perfumes')}
                            </Typography>
                        </Link>

                        <Link to={`/candle-reed`}>
                            <Typography
                                className={`font-normal text-sm capitalize`}
                            >
                                {t('candles&reed')}
                            </Typography>
                        </Link>

                    </div>


                    <div className="flex flex-col items-start sm:col-span-2  md:col-span-2 col-span-1 sm:mb-0 mb-4">
                        <Typography variant="paragraph" className="font-medium mb-2">
                            NEWSLETTER
                        </Typography>

                        <Typography
                            className={`font-normal text-sm mb-2`}
                        >
                            {t('subscribe-message')}
                        </Typography>

                        <div className="w-full flex items-center border border-primary bg-white">
                            <input type='email' placeholder='E-mail' className={`px-2 py-1 flex-1 placeholder:font-light  placeholder:text-primary`} />
                            <button className="bg-transparent px-2 py-[5px] text-primary">
                                {t('subscribe')}
                            </button>
                        </div>

                    </div>


                </div>

                <hr className="my-3 border-primary" />
                <p className="text-center">
                    Copyright &copy; Secret Scenta 2024. {t('all-rights-reserved')}.
                </p>
            </div>
        </footer>
    );
};

export default Footer;
