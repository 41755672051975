import React, { useContext, useEffect, useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import Sidebar from './Sidebar';

import { Outlet, useLocation } from 'react-router-dom';
import { useZone } from '../../contexts/ZoneContext';
import { SelectZone } from '../../widgets/modals/zones/SelectZone';

const Layout = (props) => {
    const { zone, zones, setZone, loading, error } = useZone();

    const [open, setOpen] = useState(false); // State to control modal visibility

    const location = useLocation(); // Get current location (route)


    // useEffect(() => {
    //     // If no zone is selected, show the modal
    //     if (!zone) {
    //         setOpen(true);
    //     }
    // }, [zone]);

    useEffect(() => {
        // If no zone is selected and not on the login page, show the modal
        if (!zone && location.pathname !== '/login') {
            setOpen(true);
        }
    }, [zone, location.pathname])

    const handleOpen = () => {
        setOpen(!open);
    };


    return (
        <div className="overflow-hidden">
            <Header />
            <main>
                <Outlet />
            </main>
            <Sidebar />
            <Footer />


            <SelectZone
                open={open}
                handleOpen={handleOpen}
                setZone={setZone}
                zones={zones}
                zone={zone}
            />

        </div>
    )
}

export default Layout