import { Typography, Alert, Spinner } from '@material-tailwind/react';
import React, { useContext, useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useTranslation } from 'react-i18next';

import ae from '../../data/ae.json'

import { CartContext } from '../../contexts/CartContext';
import { useCurrency } from "../../contexts/CurrencyContext";

import { BsArrowRight, BsCartX } from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom';
import { API_LINK } from '../../apiConfig';
import axios from 'axios';
import { SuccessOrder } from '../../widgets/modals/orders/SuccessOrder';
import { useZone } from '../../contexts/ZoneContext';

const PlaceOrder = () => {
    const { t } = useTranslation();

    const { currency, rates } = useCurrency();

    const { zone } = useZone();

    const navigate = useNavigate();

    const { cart, clearCart, total } = useContext(CartContext);

    const [isActive, setIsActive] = useState(false);

    const [listWilayas, setListWilayas] = useState([]);

    const [listCountries, setListCountries] = useState([])

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(!open);

    const [loadingPayment, setLoadingPayment] = useState(false);

    const [promoCode, setPromoCode] = useState('');
    const [responsePromoCode, setResponsePromoCode] = useState('');

    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [country, setCountry] = useState('');
    const [city, setCity] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [deliveryType, setDeliveryType] = useState('');
    const [address, setAddress] = useState('');

    const [isFirstnameEmpty, setIsFirstnameEmpty] = useState(false);
    const [isLastnameEmpty, setIsLastnameEmpty] = useState(false);
    const [isPhoneEmpty, setIsPhoneEmpty] = useState(false);
    const [isCountryEmpty, setIsCountryEmpty] = useState(false);
    const [isCityEmpty, setIsCityEmpty] = useState(false);
    const [isPostalCodeEmpty, setIsPostalCodeEmpty] = useState(false);
    const [isDeliveryTypeEmpty, setIsDeliveryTypeEmpty] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            window.scrollY > 60 ? setIsActive(true) : setIsActive(false);
        });
    });

    const transformCartForDatabase = (cart) => {
        const finalCart = cart.map(({ id, name, type, specification, images, price, promotion, amount }) => ({
            quantity: amount,
            unitPrice: promotion ? ((responsePromoCode && responsePromoCode?.promoCode.discountType === 'percentage') ? ((100 - responsePromoCode?.promoCode.discountValue) / 100) * (promotion) : promotion) : ((responsePromoCode && responsePromoCode?.promoCode.discountType === 'percentage') ? ((100 - responsePromoCode?.promoCode.discountValue) / 100) * (price) : price),
            productId: id,
            name: name + ' ' + type + ' ' + specification,
            image: images[0]
        }));
        return finalCart;
    };

    useEffect(() => {
        axios.get(`${API_LINK}/shippingprices/countries-by-zone/${zone}`).then(({ data }) => {
            setListCountries(data);
        }).catch((error) => {
            console.log(error);
        })
    }, [zone])


    const validateInputs = () => {
        const isFirstnameEmpty = firstname.trim() === '';
        const isLastnameEmpty = lastname.trim() === '';
        const isPhoneEmpty = phone.trim() === '';
        const isCountryEmpty = country.trim() === '';
        const isCityEmpty = city.trim() === '';
        const isPostalCodeEmpty = postalCode.trim() === '';
        const isDeliveryTypeEmpty = deliveryType.trim() === '';

        // Mettre à jour les classes d'erreur ici (vous pouvez garder les états si nécessaire)
        setIsFirstnameEmpty(isFirstnameEmpty);
        setIsLastnameEmpty(isLastnameEmpty);
        setIsPhoneEmpty(isPhoneEmpty);
        setIsCountryEmpty(isCountryEmpty)
        setIsCityEmpty(isCityEmpty);
        setIsPostalCodeEmpty(isPostalCodeEmpty);
        setIsDeliveryTypeEmpty(isDeliveryTypeEmpty);

        // Retourner vrai seulement si tous les champs sont remplis
        return !isFirstnameEmpty && !isLastnameEmpty && !isPhoneEmpty && !isCountryEmpty && !isCityEmpty && !isPostalCodeEmpty;
    };


    const makePayment = async () => {
        const stripe = await loadStripe('pk_live_51Q9VemRoYfW3NwviqIRbUNFeaCqtuTStglNZpaPx9bc2l8YfHu1sw8FD3ejLUhTqpko4XC42DGgueTKL0ll73fdE00vFGpu1IF');
        // const stripe = await loadStripe('pk_test_51Q9VemRoYfW3NwviQwo964xy3GpTFU2JBXbpzWdolCmWxWHqW8grLptjAeqPMv3a6gJ9UsmuA5XSsWtnBOAnXGmM00ZizfS2Lb');

        const body = {
            customerName: `${firstname} ${lastname}`,
            customerEmail: email,
            customerPhone: phone,
            shippingCountry: country,
            shippingCity: city,
            shippingZipCode: postalCode,
            shippingAddress: address,
            shippinhAmount: showDeliveryPrice(country),
            orderDetails: transformCartForDatabase(cart)
        }

        if (validateInputs() && cart.length !== 0) {

            try {
                setLoadingPayment(true);
                const { data: orderData } = await axios.post(`${API_LINK}/orders`, body);

                axios.post(`${API_LINK}/stripe`, {
                    orderId: orderData.id,  // Send the orderId to associate the order with the session
                    ...body
                }).then(({ data }) => {
                    stripe.redirectToCheckout({
                        sessionId: data
                    })

                    setLoadingPayment(false);

                }).catch((error) => {
                    console.log(error);
                    setLoadingPayment(false);
                });

                // // Step 3: Redirect to Stripe Checkout page
                // const { sessionId } = sessionData;
                // const result = await stripe.redirectToCheckout({ sessionId });

            } catch (error) {
                console.error(error);
                alert('There was an error creating the payment session. Please try again.');
                setLoadingPayment(false);
            }
        } else {
            alert('Veuillez remplir tous les champs');
        }
    }

    const addOrderHandler = () => {
        // Valider les entrées et vérifier que le panier n'est pas vide
        if (validateInputs() && cart.length !== 0) {
            axios.post(`${API_LINK}/orders`, {
                customerName: `${firstname} ${lastname}`,
                customerEmail: email,
                customerPhone: phone,
                // shippingType: deliveryType,
                shippingCountry: 'ae',
                shippingCity: city,
                shippingZipCode: postalCode,
                shippingAddress: address,
                promoCodeId: (responsePromoCode && responsePromoCode?.isValid) ? parseInt(responsePromoCode?.promoCode.id) : null,
                orderDetails: transformCartForDatabase(cart)
            }).then(({ data }) => {
                setOpen(true);
                setTimeout(() => {
                    navigate("/shop");
                    clearCart();
                }, 2000);
            }).catch((error) => {
                console.log(error);
            });
        } else {
            alert('Veuillez remplir tous les champs');
        }
    };



    const promoCodeHandler = () => {
        if (promoCode) {
            axios.post(`${API_LINK}/promocodes/verify`, {
                code: promoCode
            }).then(({ data }) => {
                setResponsePromoCode(data);
            }).catch((error) => {
                console.log(error);
            })
        } else {
            alert('Veuillez remplir tous les champs')
        }
    }


    // const showDeliveryPriceOld = (wilaya, deliveryType) => {
    //     const selectedWilaya = listWilayas.find(item => item.destination === wilaya);
    //     if (selectedWilaya) {
    //         if (deliveryType) {
    //             if (deliveryType === 'economical') {
    //                 return selectedWilaya.economical;
    //             } else if (deliveryType === 'express') {
    //                 return selectedWilaya.express
    //             }
    //         } else {
    //             return 0;
    //         }

    //     } else {
    //         return 0;
    //     }
    // };


    const showDeliveryPrice = (country) => {
        const selectedCountry = listCountries.find(item => item.country === country);
        if (selectedCountry) {
            return selectedCountry.price
        } else {
            return 0;
        }
    };




    const [loadingRates, setLoadingRates] = useState(true);

    // Set loading to false after rates are fetched
    useEffect(() => {
        if (Object.keys(rates).length > 0) {
            setLoadingRates(false);
        }
    }, [rates]);


    const convertToCurrency = (amount) => {
        if (!loadingRates && Object.keys(rates).length > 0 && currency && rates[currency]) {
            return (amount * rates[currency]).toFixed(2);
        }
        return amount.toFixed(2);
    };

    return (
        <div>
            <div
                className={`${isActive ? 'h-[72px]' : 'h-[88px]'} top-0 bg-white fixed w-full transition-all`}
            ></div>

            <div className='w-full p-10 bg-primary mt-[100px] text-white flex sm:flex-row flex-col justify-evenly items-center'>
                <Typography variant="lead" className='sm:block hidden'>
                    <span className="uppercase">{t('my-cart')}</span>
                </Typography>
                <BsArrowRight className="text-2xl sm:block hidden" />
                <Typography variant="lead">
                    <span className="border-b-2 border-white uppercase">{t('order-validation')}</span>
                </Typography>
                <BsArrowRight className="text-2xl sm:block hidden" />
                <Typography variant="lead" className='sm:block hidden'>
                    <span className='uppercase'>{t('order-completed')}</span>
                </Typography>
            </div>


            {cart.length !== 0 ? (<div className='grid sm:grid-cols-2 grid-cols-1 w-full sm:mb-0 mb-20 text-info'>
                <div className='p-8 w-full h-full'>
                    <Typography variant='h4' className='capitalize'>
                        {t('invoicing-shipping')}
                    </Typography>

                    <div className="grid md:grid-cols-2 grid-cols-1 gap-4 mt-4">
                        <div className='flex flex-col'>
                            <Typography
                                className='ml-1'
                                variant='paragraph'
                            >
                                {t('lastname')}
                            </Typography>
                            <input type='text' placeholder={t('lastname')} id="lastname" name="lastname" onChange={(e) => setLastname(e.target.value)} className={`px-2 py-1 border placeholder:font-light ${isLastnameEmpty ? 'border-red-400' : 'border-gray-400'}`} />
                        </div>
                        <div className='flex flex-col'>
                            <Typography
                                className='ml-1'
                                variant='paragraph'
                            >
                                {t('firstname')}
                            </Typography>
                            <input type='text' placeholder={t('firstname')} id="firstname" name="firstname" onChange={(e) => setFirstname(e.target.value)} className={`px-2 py-1 border placeholder:font-light ${isFirstnameEmpty ? 'border-red-400' : 'border-gray-400'}`} />
                        </div>

                        <div className='flex flex-col'>
                            <Typography
                                className='ml-1'
                                variant='paragraph'
                            >
                                {t('phone-number')}
                            </Typography>
                            <input type='tel' placeholder={t('phone-number')} id="phone" name="phone" max={13} onChange={(e) => setPhone(e.target.value)} className={`px-2 py-1 border placeholder:font-light ${isPhoneEmpty ? 'border-red-400' : 'border-gray-400'}`} />
                        </div>

                        <div className='flex flex-col'>
                            <Typography
                                className='ml-1'
                                variant='paragraph'
                            >
                                {t('email')}
                            </Typography>
                            <input type='email' placeholder={t('email')} id="email" name="email" onChange={(e) => setEmail(e.target.value)} className={`px-2 py-1 border placeholder:font-light border-gray-400`} />
                        </div>

                        <div className='flex flex-col'>
                            <Typography
                                className='ml-1'
                                variant='paragraph'
                            >
                                {t('country')}
                            </Typography>
                            <select onChange={(e) => setCountry(e.target.value)} id="country" name="country" className={`px-2 py-1 border placeholder:font-light bg-white ${isCountryEmpty ? 'border-red-400' : 'border-gray-400'}`}>
                                <option value="" selected disabled>{t('selectCountry')}</option>
                                {listCountries.map((country) => (
                                    <option key={country.country} value={country.country}>{country.country}</option>
                                ))}
                            </select>
                        </div>


                        <div className='flex flex-col'>
                            <Typography
                                className='ml-1'
                                variant='paragraph'
                            >
                                {t('city')}
                            </Typography>
                            {/* <select onChange={(e) => setCity(e.target.value)} className={`px-2 py-1 border placeholder:font-light bg-white ${isCityEmpty ? 'border-red-400' : 'border-gray-400'}`}>
                                <option value="" selected disabled>{t('selectCity')}</option>
                                {ae.map((country) => (
                                    <option key={country.city} value={country.city}>{country.city}</option>
                                ))}
                            </select> */}
                            <input type='text' placeholder={t('city')} id="city" name="city" onChange={(e) => setCity(e.target.value)} className={`px-2 py-1 border placeholder:font-light ${isCityEmpty ? 'border-red-400' : 'border-gray-400'}`} />
                        </div>


                        <div className='flex flex-col'>
                            <Typography
                                className='ml-1'
                                variant='paragraph'
                            >
                                {t('zipCode')}
                            </Typography>
                            <input type='text' placeholder={t('zipCode')} id="zip" name="zip" maxLength={6} onChange={(e) => setPostalCode(e.target.value)} className={`px-2 py-1 border placeholder:font-light ${isPostalCodeEmpty ? 'border-red-400' : 'border-gray-400'}`} />
                        </div>


                        {/* <div className='flex flex-col'>
                            <Typography
                                className='ml-1'
                                variant='paragraph'
                            >
                                Type de livraison
                            </Typography>
                            <select onChange={(e) => setDeliveryType(e.target.value)} className={`px-2 py-1 border placeholder:font-light bg-white ${isDeliveryTypeEmpty ? 'border-red-400' : 'border-gray-400'}`}>
                                <option value="" selected disabled>Sélectionner un type</option>
                                <option value="economical">Économique</option>
                                <option value="express">Express</option>
                            </select>
                        </div> */}

                        <div className='flex flex-col'>
                            {/* md:col-span-2 col-span-1 */}
                            <Typography
                                className='ml-1'
                                variant='paragraph'
                            >
                                {t('address')}
                            </Typography>
                            <input type='text' placeholder={t('address')} id="address" name="address" onChange={(e) => setAddress(e.target.value)} className='px-2 py-1 border border-gray-400 placeholder:font-light ' />
                        </div>
                    </div>
                </div>
                <div className='w-full p-8 bg-gray-100'>
                    <Typography
                        className='ml-1 mb-2 uppercase text-center'
                        variant='h4'
                    >
                        {t('your-order')}
                    </Typography>
                    <div className='bg-white p-2'>
                        <div className='flex justify-between items-center p-2 border-b-2'>
                            <Typography
                                className='ml-1 uppercase text-center'
                                variant='h6'
                            >
                                {t('product')}
                            </Typography>
                            <Typography
                                className='ml-1 uppercase text-center'
                                variant='h6'
                            >
                                {t('subtotal')}
                            </Typography>
                        </div>

                        {cart.map((item) => (
                            <div key={item.id} className='flex justify-between items-center p-2 border-b'>
                                <Typography
                                    className='ml-1 uppercase text-center font-light'
                                    variant='h6'
                                >
                                    {item.name} X {item.amount}
                                </Typography>
                                <Typography
                                    className='ml-1 uppercase text-center font-light'
                                    variant='h6'
                                >
                                    {item.promotion ? convertToCurrency(item.promotion * item.amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : convertToCurrency(item.price * item.amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} {currency}
                                </Typography>
                            </div>
                        ))}

                        <div className='flex justify-between items-center p-2 border-b'>
                            <Typography
                                className='ml-1 uppercase text-center font-light'
                                variant='h6'
                            >
                                {t('shipping')}
                            </Typography>
                            <Typography
                                className='ml-1 uppercase text-center font-light'
                                variant='h6'
                            >
                                {convertToCurrency(showDeliveryPrice(country)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} {currency}
                            </Typography>
                        </div>

                        <div className='flex justify-between items-center p-2'>
                            <Typography
                                className='ml-1 text-center capitalize'
                                variant='h6'
                            >
                                {t('total')}
                            </Typography>
                            <Typography
                                className='ml-1 uppercase text-center'
                                variant='h5'
                            >
                                {convertToCurrency(total + showDeliveryPrice(country)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} {currency}
                            </Typography>
                        </div>
                    </div>


                    <div className='flex w-full mb-2 mt-4'>
                        <input type='text' placeholder={t('enterPromo')} minLength={6} maxLength={6} onChange={(e) => { setPromoCode(e.target.value); setResponsePromoCode('') }} className={` flex-1 px-2 py-2 border placeholder:font-light border-gray-400`} />
                        <button className='bg-primary text-white px-4 capitalize' onClick={() => promoCodeHandler()}>{t('apply')}</button>
                    </div>

                    <div className='flex justify-center items-center mb-2'>
                        {promoCode && <Typography color={responsePromoCode?.isValid === true ? 'green' : 'red'} variant='lead'>
                            {t(responsePromoCode?.message)}
                        </Typography>}
                    </div>

                    {responsePromoCode?.isValid === true &&
                        <div className='bg-white p-2 mb-8'>
                            <div className='flex justify-between items-center p-2 border-b-2'>
                                <Typography
                                    className='ml-1 uppercase text-center'
                                    variant='h6'
                                >
                                    {t('promotion')}
                                </Typography>
                                <Typography
                                    className='ml-1 uppercase text-center'
                                    variant='h6'
                                >
                                    {responsePromoCode?.promoCode.discountType === 'percentage' ? responsePromoCode?.promoCode.discountValue : convertToCurrency(responsePromoCode?.promoCode.discountValue)} {responsePromoCode?.promoCode.discountType === 'percentage' ? "%" : responsePromoCode?.promoCode.discountType === 'amount' && currency}
                                </Typography>
                            </div>



                            <div className='flex justify-between items-center p-2'>
                                <Typography
                                    className='ml-1 text-center capitalize'
                                    variant='h6'
                                >
                                    {t('total')}
                                </Typography>
                                <Typography
                                    className='ml-1 uppercase text-center'
                                    variant='h5'
                                >
                                    {(responsePromoCode?.promoCode.discountType === 'percentage' ? convertToCurrency(showDeliveryPrice(country) + ((100 - responsePromoCode?.promoCode.discountValue) / 100) * (total)) : responsePromoCode?.promoCode.discountType === 'amount' && convertToCurrency(((total) - responsePromoCode?.promoCode.discountValue) + showDeliveryPrice(country))).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} {currency}
                                </Typography>
                            </div>
                        </div>
                    }


                    {/* <Typography
                        className='ml-1 uppercase py-3'
                        variant='paragraph'
                    >
                        Paiement à la livraison
                    </Typography> */}

                    <button
                        // onClick={addOrderHandler}
                        onClick={makePayment}
                        className="bg-primary flex p-3 justify-center items-center text-white w-full font-medium capitalize"
                    >
                        {t('order')}
                        {loadingPayment && <Spinner className=" ml-4 h-6 w-6" />}
                    </button>

                </div>
            </div>) : (
                <div className='flex flex-col justify-center items-center h-screen  text-info'>
                    <BsCartX size={180} className='text-gray-200 m-5' />
                    <Typography variant='h3' className='text-center'>
                        {t('cart-empty')}
                    </Typography>
                    <div className='m-5'>
                        <Link to={'/'}
                            className="bg-primary flex p-3 justify-center items-center text-white w-full font-medium uppercase"
                        >
                            {t('back-shop')}
                        </Link>
                    </div>
                </div>
            )}


            <SuccessOrder open={open} handleOpen={handleOpen} />

        </div>
    )
}

export default PlaceOrder;