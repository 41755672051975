import React, { useContext, useState } from "react";
import {
    Button,
    Dialog,
    Card,
    CardBody,
    CardFooter,
    Typography,
    Spinner,
} from "@material-tailwind/react";

import { API_LINK } from "../../../apiConfig";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";

export const DeleteCode = ({ open, handleOpen, setPromoCodes, selectedPromoCode, setSelectedPromoCode }) => {
    const { authTokens } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);

    const deletePromoCodeHandler = () => {
        setLoading(true);
        if (selectedPromoCode) {
            axios.delete(`${API_LINK}/promocodes/${selectedPromoCode?.id}`, {
                headers: {
                    Authorization: `Bearer ${authTokens?.accessToken}`
                }
            }).then(({ data }) => {
                setPromoCodes((promocodes) => promocodes.filter((promocode) => promocode.id !== selectedPromoCode.id));
                closeModalHandler()
            }).catch((error) => {
                console.log(error);
            }).finally(() => {
                setLoading(false);
            });
        }
    }


    const closeModalHandler = () => {
        handleOpen();
        setSelectedPromoCode(null);
    }

    return (
        <>
            <Dialog
                size="sm"
                open={open}
                handler={closeModalHandler}
                className="bg-transparent shadow-none"
            >
                {!loading ? (<Card className="mx-auto w-full">
                    <CardBody className="flex flex-col gap-4">
                        <Typography variant="h4" color="blue-gray">
                            Supprimer un code promo
                        </Typography>
                        <div className="w-full flex justify-center">
                            <Typography variant="h6" color="blue-gray" className="w-2/3 text-center">
                                Voulez-vous vraiment supprimer le code <span className="font-bold capitalize">« {selectedPromoCode?.code} »</span> de votre liste de codes de promotion
                            </Typography>
                        </div>
                    </CardBody>
                    <CardFooter className="pt-0">
                        <Button variant="filled" className="bg-red-400 shadow-none rounded-none" onClick={() => deletePromoCodeHandler()} fullWidth>
                            Supprimer
                        </Button>
                    </CardFooter>
                </Card>
                ) : (
                    <div className="flex justify-center items-center p-5">
                        <Spinner />
                    </div>
                )}
            </Dialog>
        </>
    );
}