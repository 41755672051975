import React, { useEffect, useState } from "react";
import {
    Dialog,
    DialogBody,
    IconButton
} from "@material-tailwind/react";
import { API_LINK } from "../../../apiConfig";
import { BsX } from "react-icons/bs";
import { Link } from "react-router-dom";
import { t } from "i18next";

export const ShowAdvertisement = ({ handleOpen, open, advertisement }) => {
    const [web, setWeb] = useState(window.innerWidth >= 768);

    useEffect(() => {
        const handleResize = () => {
            setWeb(window.innerWidth >= 768);
        };

        window.addEventListener("resize", handleResize);

        // Cleanup on unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <Dialog
            size="md"
            open={open}
            handler={handleOpen}
            animate={{
                mount: { scale: 1, y: 0 },
                unmount: { scale: 0.9, y: 0 },
            }}
            className="rounded-none transition-all duration-500"
        >
            <DialogBody className="p-0 m-0 rounded-none">
                <div className="relative">
                    <img 
                        src={advertisement ? `${API_LINK}/${web ? advertisement.imageUrlWeb : advertisement.imageUrlMobile}` : ''} 
                        alt="advertisement" 
                        className="w-full" 
                    />
                    <div className="absolute top-0 right-0">
                        <IconButton onClick={handleOpen} variant="text" className="rounded-none">
                            <BsX size={32} />
                        </IconButton>
                    </div>
                    <div className="absolute inset-x-0 sm:bottom-5 bottom-2 w-full flex justify-center">
                        {advertisement?.redirectUrl && <Link to={advertisement?.redirectUrl} className="text-white sm:py-3 py-1 sm:px-8 px-4 bg-primary font-medium mt-5 hover:opacity-80">
                            {t('enjoy')}
                        </Link>}
                    </div>
                </div>
            </DialogBody>
        </Dialog>
    );
};
