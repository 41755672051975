import React, { useContext, useEffect, useState } from 'react'
import { Card, CardBody, CardFooter, IconButton, Input, Tooltip, Typography } from '@material-tailwind/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { BsPencil, BsTrash } from 'react-icons/bs'

import { format } from 'date-fns'
import { fr } from 'date-fns/locale'


import { AuthContext } from '../../contexts/AuthContext'
import { API_LINK } from '../../apiConfig'
import axios from 'axios'
import { UpdateUser } from '../../widgets/modals/users/UpdateUser'
import { DeleteUser } from '../../widgets/modals/users/DeleteUser'



const TABLE_HEAD = ["Utilisateur", "Email", "Rôle", "Date de création", ""];


const Users = () => {
    const { authTokens } = useContext(AuthContext);

    const [users, setUsers] = useState([]);
    const [search, setSearch] = useState();

    const [openUpdateUser, setOpenUpdateUser] = useState(false);
    const handleOpenUpdateUser = () => setOpenUpdateUser((cur) => !cur);

    const [openDeleteUser, setOpenDeleteUser] = useState(false);
    const handleOpenDeleteUser = () => setOpenDeleteUser((cur) => !cur);

    const [selectedUser, setSelectedUser] = useState();


    useEffect(() => {
        axios.get(`${API_LINK}/users?search=${search}`, {
            headers: {
                Authorization: `Bearer ${authTokens?.accessToken}`
            }
        }).then(({ data }) => {
            setUsers(data);
        }).catch((error) => {
            console.log(error);
        })
    }, [search])

    return (
        <div className="mt-12">
            <Card shadow={false} className="h-full w-full bg-secondary border border-gray-400 rounded-none">
                <CardBody className={`px-0 xl:overflow-hidden`} /* overflow-scroll  */>

                    <div className="mb-4 flex flex-col justify-between gap-8 md:flex-row md:items-center p-4 text-primary">
                        <div>
                            <Typography variant="h4">
                                Liste d'utilisateurs
                            </Typography>
                            <Typography className="mt-1">
                                Ce sont des détails de tous les utilisateurs
                            </Typography>
                        </div>
                        <div className="flex w-full shrink-0 gap-2 md:w-max">
                            <div className='sm:flex-1 sm:mr-6 sm:mb-0 mb-3'>
                                <Input label="Recherche" type="text" onChange={(e) => setSearch(e.target.value)} />
                            </div>
                        </div>
                    </div>

                    {users.length !== 0 ?
                        <div className={`${users.length !== 0 && 'overflow-x-scroll'}`}>
                            <table className={"w-full min-w-max table-auto text-left"}>
                                <thead>
                                    <tr>
                                        {TABLE_HEAD.map((head) => (
                                            <th
                                                key={head}
                                                className="border-y border-white bg-primary p-4"
                                            >
                                                <Typography
                                                    variant="paragraph"
                                                    color="white"
                                                    className=" leading-none"
                                                >
                                                    {head}
                                                </Typography>
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {users.map(
                                        (
                                            {
                                                id,
                                                firstname,
                                                lastname,
                                                email,
                                                role,
                                                isEmailConfirmed,
                                                createdAt
                                            },
                                            index,
                                        ) => {
                                            const isLast = index === users.length - 1;
                                            const classes = isLast
                                                ? "p-4"
                                                : "p-4 border-b border-blue-gray-50";

                                            return (
                                                <tr key={id} className="text-primary">
                                                    <td className={classes}>
                                                        <div className="flex items-center gap-3">
                                                            <span className={`w-3 h-3 rounded-full ${isEmailConfirmed ? 'bg-green-400' : 'bg-red-400'}`}></span>
                                                            <Typography
                                                                variant="paragraph"
                                                                color="blue-gray"
                                                                className="capitalize text-primary"
                                                            >
                                                                {lastname} {firstname}
                                                            </Typography>
                                                        </div>
                                                    </td>

                                                    <td className={classes}>
                                                        <Tooltip className="" content={
                                                            <Typography
                                                                variant="small"
                                                            >
                                                                {email}
                                                            </Typography>}>
                                                            <Typography
                                                                variant="paragraph"
                                                                color="blue-gray"
                                                                className="text-primary"
                                                            >
                                                                {email.length > 14 ? email.substring(0, 12) + '...' : email}
                                                            </Typography>
                                                        </Tooltip>
                                                    </td>

                                                    <td className={classes}>
                                                        <div className="flex items-center gap-3">
                                                            <Typography
                                                                variant="paragraph"
                                                                color="blue-gray"
                                                                className="capitalize text-primary"
                                                            >
                                                                {role === "admin" ? "Administrateur" : role === "stock" ? "Gestionnaire de stock" :  role === "delivery" && "Gestionnaire de livraison"}
                                                            </Typography>
                                                        </div>
                                                    </td>

                                                    <td className={classes}>
                                                        <Typography
                                                            variant="small"
                                                            color="blue-gray"
                                                            className="capitalize text-primary"
                                                        >
                                                            {format(new Date(createdAt), 'dd MMMM yyyy', { locale: fr })}
                                                        </Typography>
                                                    </td>

                                                    <td className={classes}>
                                                        <Tooltip content="Modifier">
                                                            <IconButton variant="text" onClick={() => { setSelectedUser({ id, firstname, lastname, email, role, createdAt }); handleOpenUpdateUser() }}>
                                                                <BsPencil className="h-4 w-4 text-primary" />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip content="Supprimer">
                                                            <IconButton disabled={authTokens?.user.id === id || role === 'admin'} variant="text" onClick={() => { setSelectedUser({ id, firstname, lastname }); handleOpenDeleteUser() }}>
                                                                <BsTrash className="h-4 w-4 text-primary" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </td>
                                                </tr>
                                            );
                                        },
                                    )}
                                </tbody>
                            </table>
                        </div> :
                        (
                            <div className="w-full">
                                <div className="flex flex-col justify-center items-center bg-primary text-secondary p-6 rounded-none mx-6">
                                    <ExclamationTriangleIcon className='w-20 mb-4' />
                                    <Typography variant="lead" className="">
                                        {search ? "Il n'existe pas d'utilisateur avec ce nom" : "Vous n'avez encore d'utilisateurs !"}
                                    </Typography>
                                </div>
                            </div>
                        )}
                </CardBody>
                <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
                    <div className='p-2'></div>
                </CardFooter>
            </Card>

            <DeleteUser open={openDeleteUser} handleOpen={handleOpenDeleteUser} setUsers={setUsers} selectedUser={selectedUser} setSelectedUser={setSelectedUser} />
            <UpdateUser open={openUpdateUser} handleOpen={handleOpenUpdateUser} setUsers={setUsers} selectedUser={selectedUser} setSelectedUser={setSelectedUser} />
        </div>
    )
}

export default Users