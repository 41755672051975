import React, { useEffect, useRef, useState } from 'react';
import { motion, useInView, useAnimation } from 'framer-motion'
import { Tooltip, Typography } from '@material-tailwind/react';
import emailjs from '@emailjs/browser'

import wilayas from '../../data/wilayas.json'

import banner from '../../img/business/banner.png';
import moment from '../../img/senthome/moment.png';


import grid1 from '../../img/business/grid1.png';
import grid2 from '../../img/business/grid2.png';
import grid3 from '../../img/business/grid3.png';
import grid4 from '../../img/business/grid4.png';
import grid5 from '../../img/business/grid5.png';

import icon1 from '../../img/business/Business Icon/1.png';
import icon2 from '../../img/business/Business Icon/2.png';
import icon3 from '../../img/business/Business Icon/3.png';
import icon4 from '../../img/business/Business Icon/4.png';
import icon5 from '../../img/business/Business Icon/5.png';

import serviceclient from '../../img/business/service-clients.png';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Services = () => {
    const { t } = useTranslation();


    const bannerControls = useAnimation()
    const refBanner = useRef(null);
    const isInViewBanner = useInView(refBanner);

    const galleryControls = useAnimation()
    const refGallery = useRef(null);
    const isInViewGallery = useInView(refGallery);

    const infoControls = useAnimation()
    const refInfo = useRef(null);
    const isInViewInfo = useInView(refInfo);

    const textControls = useAnimation()
    const refText = useRef(null);
    const isInViewText = useInView(refText);

    // const contactControls = useAnimation()
    // const refContact = useRef(null);
    // const isInViewContact = useInView(refContact);


    useEffect(() => {
        if (isInViewBanner) {
            bannerControls.start("visible")
        }

        if (isInViewGallery) {
            galleryControls.start("visible")
        }

        if (isInViewInfo) {
            infoControls.start("visible")
        }

        if (isInViewText) {
            textControls.start("visible")
        }



    }, [isInViewBanner, isInViewGallery, isInViewInfo, isInViewText])



    const form = useRef();
    const [isActive, setIsActive] = useState(false);

    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [company, setCompany] = useState('');
    const [wilaya, setWilaya] = useState('');
    const [message, setMessage] = useState('');

    const [isFirstnameEmpty, setIsFirstnameEmpty] = useState(false);
    const [isLastnameEmpty, setIsLastnameEmpty] = useState(false);
    const [isPhoneEmpty, setIsPhoneEmpty] = useState(false);
    const [isEmailEmpty, setIsEmailEmpty] = useState(false);
    const [isCompanyEmpty, setIsCompanyEmpty] = useState(false);
    const [isWilayaEmpty, setIsWilayaEmpty] = useState(false);
    const [isMessageEmpty, setIsMessageEmpty] = useState(false);

    const validateInputs = () => {
        setIsFirstnameEmpty(firstname.trim() === '');
        setIsLastnameEmpty(lastname.trim() === '');
        setIsPhoneEmpty(phone.trim() === '');
        setIsEmailEmpty(email.trim() === '');
        setIsCompanyEmpty(company.trim() === '');
        setIsWilayaEmpty(wilaya.trim() === '');
        setIsMessageEmpty(message.trim() === '');
        return (
            firstname.trim() !== '' &&
            lastname.trim() !== '' &&
            phone.trim() !== '' &&
            email.trim() !== '' &&
            company.trim() !== '' &&
            wilaya.trim() !== '' &&
            message.trim() !== ''
        );
    };


    const contactHandler = async (e) => {
        e.preventDefault();
        if (validateInputs()) {
            emailjs.sendForm('service_ghatcog', 'template_2uvv0kr', form.current, '_gU1vBHGU3VO8PBw9')
                .then((result) => {
                    console.log(result.text);
                    defaultValuesForInputs();
                }, (error) => {
                    console.log(error.text);
                });

        } else {
            alert('Veuillez remplir tous les champs')
        }
        e.target.reset();
    }

    const defaultValuesForInputs = () => {
        setFirstname('');
        setLastname('');
        setEmail('');
        setPhone('');
        setCompany('');
        setWilaya('');
        setMessage('');
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            window.scrollY > 60 ? setIsActive(true) : setIsActive(false);
        });
    });

    return (
        <div>
            <div
                className={`${isActive ? 'h-[72px]' : 'h-[88px]'} top-0 bg-white fixed w-full transition-all`}
            ></div>

            {/* <div className='sm:mt-[110px] mt-[96px] text-info'> */}
            <div className='sm:mt-[80px] mt-[60px] text-info'>


                <div ref={refBanner}>
                    <motion.section
                        variants={{
                            hidden: { opacity: 0, y: 75 },
                            visible: { opacity: 1, y: 0 }
                        }}
                        initial='hidden'
                        animate={bannerControls}
                        transition={{ duration: 0.5, delay: 0.25 }}
                        className='relative'
                    >
                        <img src={banner} className="w-full h-screen sm:object-contain object-cover" loading='lazy' />
                        <div className='absolute inset-0 sm:w-1/2 sm:flex hidden justify-center items-center'>
                            <div className='flex flex-col items-center'>
                                <Typography className='flex flex-col items-center text-white sm:text-4xl text-2xl font-medium font-secondary text-center'>
                                    <span className='tracking-widest mb-4'>{t('services-banner-title-1')}</span>
                                    <span className='tracking-widest mb-4'>{t('services-banner-title-2')}</span>
                                </Typography>
                                <Typography className='flex flex-col items-center text-white sm:text-2xl text-xl font-medium font-secondary text-center'>
                                    {t('services-banner-description')}
                                </Typography>

                                <Link to='/diffuser' className="text-secondary py-4 px-8 bg-transparent font-medium mt-5 border border-secondary hover:scale-105 transition duration-500">
                                    {t('discover')}
                                </Link>
                            </div>
                        </div>
                    </motion.section>
                </div>

                <section className="mt-10">
                    <hr className="w-1/2 border-1 border-primary"></hr>
                    <div className="container mx-auto sm:w-2/3 py-10">
                        <Typography variant='h5' className='text-info text-center font-light'>
                            {t('services-experience-1')}
                        </Typography>

                        <Typography variant='h4' className='text-primary font-secondary tracking-widest text-center font-medium mt-4'>
                            {t('services-experience-2')}
                        </Typography>
                    </div>
                    <hr className="w-1/2 border-1 border-primary float-right"></hr>
                </section>

                <div ref={refGallery}>
                    <motion.section
                        variants={{
                            hidden: { opacity: 0, y: 75 },
                            visible: { opacity: 1, y: 0 }
                        }}
                        initial='hidden'
                        animate={galleryControls}
                        transition={{ duration: 0.5, delay: 0.25 }}
                        className="mt-10"
                    >
                        <div className="container mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                            <div className="col-span-1">
                                <div className='w-full h-64 sm:h-full bg-secondary sm:relative'>
                                    {/* <img src={grid1} className='sm:absolute sm:top-0 sm:-right-3' /> */}
                                    <div className='w-full h-full sm:h-3/4 bg-cover bg-center sm:absolute sm:top-0 sm:-right-3' style={{ backgroundImage: `url(${grid1})` }}></div>
                                </div>
                            </div>

                            <div className="col-span-1 grid grid-rows-1 sm:grid-rows-2 gap-0 sm:gap-6">
                                <div className='w-full h-64 flex items-center'>
                                    <Typography variant='paragraph' className='text-info text-justify'>
                                        {t('services-experience-3')}
                                    </Typography>
                                </div>
                                <div className='w-full h-64 sm:h-full bg-cover bg-center  mt-6 sm:mt-0' style={{ backgroundImage: `url(${grid2})` }}></div>
                            </div>


                            <div className="col-span-1">
                                <div className='w-full h-64 sm:h-full bg-secondary sm:relative'>
                                    {/* <img src={grid3} className='sm:h-3/4' /> */}
                                    <div className='w-full h-full bg-cover bg-center sm:h-3/4 sm:absolute sm:bottom-6' style={{ backgroundImage: `url(${grid3})` }}></div>
                                </div>
                            </div>

                            <div className="col-span-1 grid grid-rows-1 sm:grid-rows-2 gap-0 sm:gap-6">
                                <div className='w-full h-64 sm:h-full bg-cover bg-center' style={{ backgroundImage: `url(${grid4})` }}></div>
                                <div className='w-full h-64 sm:h-full bg-cover mt-6 sm:mt-0' style={{ backgroundImage: `url(${grid5})` }}></div>
                            </div>

                        </div>
                    </motion.section>
                </div>


                {/* <div ref={refInfo}>
                    <motion.section
                        variants={{
                            hidden: { opacity: 0, y: 75 },
                            visible: { opacity: 1, y: 0 }
                        }}
                        initial='hidden'
                        animate={infoControls}
                        transition={{ duration: 0.5, delay: 0.25 }}
                        className="mt-10"
                    >
                     
                    </motion.section>
                </div> */}

                <div ref={refInfo}>
                    <motion.section
                        variants={{
                            hidden: { opacity: 0, y: 75 },
                            visible: { opacity: 1, y: 0 }
                        }}
                        initial='hidden'
                        animate={infoControls}
                        transition={{ duration: 0.5, delay: 0.25 }}
                        className="mt-10"
                    >
                        <div className="container mx-auto sm:w-3/4 py-10">
                            <Typography variant='h4' className='text-info text-center font-secondary tracking-wider font-semibold'>
                                {t('services-icons-title')}
                            </Typography>

                            <Typography variant='h4' className='text-info text-center font-secondary tracking-wider font-normal mt-4'>
                                {t('services-icons-description')}
                            </Typography>

                            <div className='flex flex-row flex-wrap items-center justify-center gap-5 mt-10'>
                                <div className='flex justify-center h-32 w-32 sm:mb-2 mb-0  hover:scale-105 transition duration-500'>
                                    <img src={icon1} className='h-full' loading='lazy' />
                                </div>

                                <div className='flex justify-center h-36 w-36 hover:scale-105 transition duration-500'>
                                    <img src={icon2} className='h-full' loading='lazy' />
                                </div>

                                <div className='flex justify-center h-36 w-36 hover:scale-105 transition duration-500'>
                                    <img src={icon3} className='h-full' loading='lazy' />
                                </div>

                                <div className='flex justify-center h-36 w-36 hover:scale-105 transition duration-500'>
                                    <img src={icon4} className='h-full' loading='lazy' />
                                </div>

                                <div className='flex justify-center h-36 w-36 hover:scale-105 transition duration-500'>
                                    <img src={icon5} className='h-full' loading='lazy' />
                                </div>

                            </div>
                        </div>
                    </motion.section>
                </div>

                <div ref={refText}>
                    <motion.section
                        variants={{
                            hidden: { opacity: 0, y: 75 },
                            visible: { opacity: 1, y: 0 }
                        }}
                        initial='hidden'
                        animate={textControls}
                        transition={{ duration: 0.5, delay: 0.25 }}
                        className="my-20"
                    >
                        <div className="mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 gap-6 relative">
                            <div className='px-6 md:px-6 py-6 col-span-1 md:col-span-2 md:w-2/3 place-self-center z-20'>
                                <Typography variant='lead' className='text-primary font-secondary tracking-wider font-medium text-center mb-4'>
                                    {t('scent-moment-title')}
                                </Typography>
                                <Typography variant='paragraph' className='text-info text-justify my-6'>
                                    {t('scent-moment-description')}
                                </Typography>
                            </div>

                            <div className='col-span-1 md:col-span-3 z-20'>
                                <div className='w-full h-64 sm:h-full bg-cover bg-center' style={{ backgroundImage: `url(${moment})` }}></div>
                            </div>

                            {/* <div className='sm:block hidden h-48 bg-secondary absolute w-full z-10'></div> */}
                        </div>
                    </motion.section>
                </div>


            </div>
        </div>
    )
}

export default Services