import React, { useContext, useEffect, useState } from 'react'
import { Button, IconButton, Option, Select, Typography } from '@material-tailwind/react'
import { PlusIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import axios from 'axios'

import { AuthContext } from '../../contexts/AuthContext'
import { API_LINK } from '../../apiConfig'

import ProductCard from '../../components/dashboard/ProductCard'
import { AddUpdateProduct } from '../../widgets/modals/products/AddUpdateProduct'
import { DeleteProduct } from '../../widgets/modals/products/DeleteProduct'


const Products = () => {
  const { authTokens } = useContext(AuthContext);

  const [openAddProduct, setOpenAddProduct] = useState(false);
  const handleOpenAddProduct = () => setOpenAddProduct((cur) => !cur);

  const [openDeleteProduct, setOpenDeleteProduct] = useState(false);
  const handleOpenDeleteProduct = () => setOpenDeleteProduct((cur) => !cur);

  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();

  const [selectedCategory, setSelectedCategory] = useState(undefined);
  const [selectedType, setSelectedType] = useState(undefined);
  const [selectedSpecification, setSelectedSpecification] = useState(undefined);

  const [page, setPage] = useState(1);
  const perPage = 10;

  const [numberOfPages, setNumberOfPages] = useState(0);


  useEffect(() => {
    axios.get(`${API_LINK}/products?page=${page}&perPage=${perPage}&category=${selectedCategory}&type=${selectedType}&specification=${selectedSpecification}`, {
      headers: {
        Authorization: `Bearer ${authTokens?.accessToken}`
      }
    }).then(({ data }) => {
      setProducts(data.products);
      setNumberOfPages(data.totalPages);

    }).catch((error) => {
      console.log(error);
    })
  }, [page, selectedCategory, selectedType, selectedSpecification])

  const visiblePages = 10; // Number of visible page numbers

  const firstPage = Math.max(1, page - Math.floor(visiblePages / 2));
  const lastPage = Math.min(numberOfPages, firstPage + visiblePages - 1);

  const pageNumbers = Array.from({ length: lastPage - firstPage + 1 }, (_, i) => firstPage + i)



  return (
    <div className="mt-12">
      <div className="flex justify-between flex-row items-center pb-4">
        <div></div>


        <div className='flex items-center'>

          <div className="sm:flex-1 sm:mr-6 sm:mb-0 mb-3">
            <Select label="Catégorie" value={selectedCategory} onChange={(e) => { setSelectedCategory(e); setSelectedType(undefined); setSelectedSpecification(undefined) }}>
              <Option value={undefined}>Tous</Option>
              <Option value="diffuseur">Diffuseur</Option>
              <Option value="parfum">Parfum</Option>
              <Option value="candle-reed">Candles & Reed</Option>
            </Select>
          </div>

          {selectedCategory && <div className="sm:flex-1 sm:mr-6 sm:mb-0 mb-3">
            {selectedCategory === "diffuseur" && (
              <Select label="Type" value={selectedType} onChange={(e) => { setSelectedType(e); setSelectedSpecification(undefined) }}>
                <Option value="business">Business</Option>
                <Option value="home">Home</Option>
              </Select>
            )}

            {selectedCategory === "parfum" && (
              <Select label="Type" value={selectedType} onChange={(e) => { setSelectedType(e); setSelectedSpecification(undefined) }}>
                <Option value="oriental">Oriental</Option>
                <Option value="fresh">Fresh</Option>
                <Option value="floral">Floral</Option>
              </Select>
            )}

            {selectedCategory === "candle-reed" && (
              <Select label="Type" value={selectedType} onChange={(e) => { setSelectedType(e); setSelectedSpecification(undefined) }}>
                <Option value="type1">Type 1</Option>
                <Option value="type2">Type 2</Option>
                <Option value="type3">Type 3</Option>
              </Select>
            )}
          </div>}

          {(selectedCategory && selectedType) && <div className="sm:flex-1 sm:mr-6 sm:mb-0 mb-3">
            {(selectedCategory === "diffuseur" && selectedType === "home") && (
              <Select label="Spécification" value={selectedSpecification} onChange={(e) => setSelectedSpecification(e)}>
                <Option value="home">Home</Option>
                <Option value="voiture">Voiture</Option>
                <Option value="sanitaire">Sanitaire</Option>
              </Select>
            )}

            {(selectedCategory === "diffuseur" && selectedType === "business") && (
              <Select label="Spécification" value={selectedSpecification} onChange={(e) => setSelectedSpecification(e)}>
                <Option value="200ml">Jusqu' à 200 ml</Option>
                <Option value="300ml">Jusqu' à 300 ml</Option>
                <Option value="400ml">Jusqu' à 400 ml</Option>
              </Select>
            )}


            {(selectedCategory === "parfum") && (
              <Select label="Spécification" value={selectedSpecification} onChange={(e) => setSelectedSpecification(e)}>
                <Option value="50ml">Jusqu' à 50 ml</Option>
                <Option value="250ml">Jusqu' à 250 ml</Option>
                <Option value="400ml">Jusqu' à 400 ml</Option>
              </Select>
            )}
          </div>}

          <Button size="sm" className="bg-primary capitalize text-base font-normal text-white flex items-center shadow-none rounded-none" onClick={() => handleOpenAddProduct()}>
            <PlusIcon className="w-4 h-4 mr-2" />
            Ajouter un produit
          </Button>
        </div>


      </div>


      {products.length !== 0 ? <div className="w-full flex flex-col">
        <div className="grid gap-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5">
          {products.map((product) => (
            <ProductCard key={product.id} data={product} setSelectedProduct={setSelectedProduct} handleOpenAddProduct={handleOpenAddProduct} handleOpenDeleteProduct={handleOpenDeleteProduct} />
          ))}
        </div>
      </div>
        : <div className="w-full">
          <div className="flex flex-col justify-center items-center bg-secondary p-6 rounded-xl text-primary">
            <ExclamationTriangleIcon className='w-20 mb-4' />
            <Typography variant="lead" className="">
              Vous n'avez encore aucun produit !
            </Typography>
          </div>
        </div>}

      {products.length !== 0 && <div className="flex items-center justify-between py-4">
        <Button disabled={page === 1} onClick={() => setPage(page - 1)} variant="outlined" size="sm" className="bg-primary text-white capitalize shadow-none rounded-none">
          Précédent
        </Button>
        <div className="flex items-center gap-2">
          {pageNumbers.map((pageNum) => (
            <IconButton onClick={() => setPage(pageNum)} key={pageNum} variant={page === pageNum ? "outlined" : "text"} size="sm" className={`${page === pageNum && "border-primary"} text-primary rounded-none`}>
              {pageNum}
            </IconButton>
          ))}
        </div>
        <Button disabled={page === numberOfPages} onClick={() => setPage(page + 1)} variant="filled" size="sm" className="bg-primary text-white capitalize shadow-none rounded-none">
          Suivant
        </Button>
      </div>}


      <AddUpdateProduct open={openAddProduct} handleOpen={handleOpenAddProduct} setProducts={setProducts} selectedProduct={selectedProduct} setSelectedProduct={setSelectedProduct} />
      <DeleteProduct open={openDeleteProduct} handleOpen={handleOpenDeleteProduct} setProducts={setProducts} selectedProduct={selectedProduct} setSelectedProduct={setSelectedProduct} />
    </div>
  )
}

export default Products