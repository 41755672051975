import React, { forwardRef, useContext, useState } from 'react';
import { AuthContext } from '../../../contexts/AuthContext';
import {
    Button,
    Dialog,
    Card,
    CardBody,
    CardFooter,
    Typography,
    Input,
    Spinner,
    Select,
    Option,
    Switch,
} from "@material-tailwind/react";
import axios from 'axios';
import { API_LINK } from '../../../apiConfig';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';

const AddCode = ({ open, handleOpen, setPromoCodes }) => {
    const { authTokens } = useContext(AuthContext);

    const DateInput = forwardRef(({ value, onClick }, ref) => (
        <Input label="Date de fin" value={value || ''} onClick={onClick} ref={ref} />
    ));

    registerLocale('fr', fr);

    const [loading, setLoading] = useState(false);

    // Initialize state variables to an empty string or null
    const [code, setCode] = useState('');
    const [discountType, setDiscountType] = useState('percentage');
    const [discountValue, setDiscountValue] = useState('');
    const [expirationDate, setExpirationDate] = useState(null);
    const [isActive, setIsActive] = useState(true);


    const AddCodeHandler = () => {
        setLoading(true);
        if (code && code.length === 6 && discountType && discountValue && expirationDate) {
            const requestConfig = {
                headers: {
                    Authorization: `Bearer ${authTokens?.accessToken}`
                }
            };

            axios.post(`${API_LINK}/promocodes/`, {
                code,
                discountType,
                discountValue: parseInt(discountValue),
                expirationDate: new Date(expirationDate),
                isActive
            }, requestConfig).then(({ data }) => {
                setPromoCodes(promocodes => [data, ...promocodes]);
                closeModalHandler();
            }).catch((error) => {
                console.log(error);
            }).finally(() => {
                setLoading(false);
            });
        } else {
            alert("Veuillez remplir tous les champs");
        }
    };

    const closeModalHandler = () => {
        handleOpen();
        // Reset states to a consistent value
        setCode('');
        setDiscountType('');
        setDiscountValue('');
        setExpirationDate(null); // Reset to null instead of undefined
        setIsActive(false);
    };

    return (
        <Dialog
            size="xs"
            open={open}
            handler={closeModalHandler}
            className="bg-transparent shadow-none"
        >
            {!loading ? (
                <Card className="mx-auto w-full">
                    <CardBody className="flex flex-col gap-4">
                        <div className='flex justify-between items-center '>
                            <Typography variant="h4" color="blue-gray">
                                Ajouter Un code de promotion
                            </Typography>
                            <div className="mx-5">
                                <Switch color="green" checked={isActive} onChange={(e) => setIsActive(e.target.checked)} />
                            </div>
                        </div>
                        <div className="grid sm:grid-cols-2 grid-cols-1 gap-4 w-full">
                            <div className='sm:col-span-2 col-span-1'>
                                <Input
                                    label="Valeur de promotion"
                                    type="number"
                                    value={discountValue}
                                    onChange={(e) => setDiscountValue(e.target.value)}
                                />
                            </div>
                            {/* <Select
                                label="Type de promotion"
                                value={discountType}
                                onChange={(e) => setDiscountType(e)}
                            >
                                <Option value="percentage">Pourcentage</Option>
                                <Option value="amount">Montant</Option>
                            </Select> */}
                            <Input
                                label="Code promotion"
                                type="text"
                                minLength={6}
                                maxLength={6}
                                value={code}
                                onChange={(e) => setCode(e.target.value)}
                            />
                            <DatePicker
                                customInput={<DateInput />}
                                selected={expirationDate}
                                locale="fr"
                                onChange={(date) => setExpirationDate(date)}
                            />
                        </div>
                    </CardBody>
                    <CardFooter className="pt-0">
                        <Button
                            variant="filled"
                            className="bg-primary rounded-none shadow-none"
                            onClick={AddCodeHandler}
                            fullWidth
                        >
                            Ajouter
                        </Button>
                    </CardFooter>
                </Card>
            ) : (
                <div className="flex justify-center items-center p-5">
                    <Spinner />
                </div>
            )}
        </Dialog>
    );
};

export default AddCode;
