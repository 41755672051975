import React, { useContext, useEffect, useState } from 'react'
import { Button, Card, CardBody, CardFooter, Chip, IconButton, Input, Option, Select, Tooltip, Typography } from '@material-tailwind/react'
import { PlusIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { BsPencil, BsTrash, BsEye, BsFileEarmarkArrowDown } from 'react-icons/bs'

import { format } from 'date-fns'
import { fr } from 'date-fns/locale'

import DatePicker from '../../components/dashboard/DatePicker'
import * as XLSX from 'xlsx';

import { AuthContext } from '../../contexts/AuthContext'
import { API_LINK } from '../../apiConfig'
import axios from 'axios'
import { DeleteOrder } from '../../widgets/modals/orders/DeleteOrder'
import { ShowOrder } from '../../widgets/modals/orders/ShowOrder'
import { UpdateStateOrder } from '../../widgets/modals/orders/UpdateStateOrder'
import AddCode from '../../widgets/modals/promocodes/AddCode'
import { DeleteCode } from '../../widgets/modals/promocodes/DeleteCode'



const TABLE_HEAD = ["Code", "Promotion", "Date de fin", "État", ""];




const PromoCodes = () => {
    const { authTokens } = useContext(AuthContext);
    const [promoCodes, setPromoCodes] = useState([]);


    const [openAddCode, setOpenAddCode] = useState(false);
    const handleOpenAddCode = () => setOpenAddCode((cur) => !cur);

    const [openDeleteCode, setOpenDeleteCode] = useState(false);
    const handleOpenDeleteCode = () => setOpenDeleteCode((cur) => !cur);


    const [selectedPromoCode, setSelectedPromoCode] = useState();

    const [date, setDate] = useState();
    const [codeSearch, setCodeSearch] = useState();

    const [page, setPage] = useState(1);
    const perPage = 10;

    const [numberOfPages, setNumberOfPages] = useState(0);


    useEffect(() => {        
        axios.get(`${API_LINK}/promocodes?page=${page}&perPage=${perPage}&date=${date}&code=${codeSearch}`, {
            headers: {
                Authorization: `Bearer ${authTokens?.accessToken}`
            }
        }).then(({ data }) => {
            setPromoCodes(data.promoCodes);
            setNumberOfPages(data.totalPages);
        }).catch((error) => {
            console.log(error);
        })
    }, [page, date, codeSearch])

    const visiblePages = 10; // Number of visible page numbers

    const firstPage = Math.max(1, page - Math.floor(visiblePages / 2));
    const lastPage = Math.min(numberOfPages, firstPage + visiblePages - 1);

    const pageNumbers = Array.from({ length: lastPage - firstPage + 1 }, (_, i) => firstPage + i);



    return (
        <div className="mt-12">


            <div className="flex justify-between flex-row items-center pb-4">
                <div></div>

                <div className='flex items-center'>
                    <Button size="sm" className="bg-primary capitalize text-base font-normal text-white flex items-center shadow-none rounded-none"
                        onClick={() => handleOpenAddCode()}
                    >
                        <PlusIcon className="w-4 h-4 mr-2" />
                        Ajouter un code
                    </Button>
                </div>
            </div>

            <Card shadow={false} className="h-full w-full bg-secondary border border-gray-400 rounded-none">
                <CardBody className={`px-0 xl:overflow-hidden`} /* overflow-scroll  */>

                    <div className="mb-4 flex flex-col justify-between gap-8 md:flex-row md:items-center p-4 text-primary">
                        <div>
                            <Typography variant="h4">
                                Codes de promotion récents
                            </Typography>
                            <Typography className="mt-1">
                                Ce sont des détails sur les derniers codes
                            </Typography>
                        </div>
                        <div className="flex w-full shrink-0 gap-2 md:w-max">

                            <div className="sm:flex-1 sm:mr-6 sm:mb-0 mb-3">
                                <Input className='rounded-none bg-white' label="Recherche par code" type="text" onChange={(e) => setCodeSearch(e.target.value)} />
                            </div>

                            <div className='sm:flex-1 sm:mr-6 sm:mb-0 mb-3'><DatePicker date={date} setDate={setDate} /></div>
                        </div>
                    </div>

                    {promoCodes.length !== 0 ?
                        <div className={`${promoCodes.length !== 0 && 'overflow-x-scroll'}`}>
                            <table className="w-full min-w-max table-auto text-left">
                                <thead>
                                    <tr>
                                        {TABLE_HEAD.map((head) => (
                                            <th
                                                key={head}
                                                className="border-y border-white bg-primary p-4"
                                            >
                                                <Typography
                                                    variant="paragraph"
                                                    color="white"
                                                    className=" leading-none"
                                                >
                                                    {head}
                                                </Typography>
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {promoCodes.map(
                                        (
                                            {
                                                id,
                                                code,
                                                discountType,
                                                discountValue,
                                                expirationDate,
                                                isActive
                                            },
                                            index,
                                        ) => {
                                            const isLast = index === promoCodes.length - 1;
                                            const classes = isLast
                                                ? "p-4"
                                                : "p-4 border-b border-blue-gray-50";

                                            return (
                                                <tr key={id} className="text-primary">
                                                    <td className={classes}>
                                                        <div className="flex items-center gap-3">
                                                            <Typography
                                                                variant="paragraph"
                                                                color="blue-gray"
                                                                className="capitalize text-primary"
                                                            >
                                                                {code}
                                                            </Typography>
                                                        </div>
                                                    </td>
                                                    <td className={classes}>
                                                        <Typography
                                                            variant="paragraph"
                                                            color="blue-gray"
                                                            className="capitalize text-primary"
                                                        >
                                                            {discountValue} {discountType === "percentage" ? "%" : discountType === "amount" && "DA"}
                                                        </Typography>
                                                    </td>

                                                    <td className={classes}>
                                                        <Typography
                                                            variant="small"
                                                            color="blue-gray"
                                                            className="capitalize text-primary"
                                                        >
                                                            {format(new Date(expirationDate), 'dd MMMM yyyy', { locale: fr })}
                                                        </Typography>
                                                    </td>
                                                    <td className={classes}>
                                                        <div className="w-max bg-white rounded-md">
                                                            <Chip
                                                                size="sm"
                                                                variant="ghost"
                                                                value={isActive === true ? "Actif" : isActive === false && 'Non Actif'}
                                                                color={
                                                                    isActive === true ? "green" : isActive === false && "red"
                                                                }
                                                            />
                                                        </div>
                                                    </td>
                                                    <td className={classes}>
                                                        <Tooltip content="Supprimer">
                                                            <IconButton variant="text" onClick={() => { setSelectedPromoCode({id, code}); handleOpenDeleteCode() }}>
                                                                <BsTrash className="h-4 w-4 text-primary" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </td>
                                                </tr>
                                            );
                                        },
                                    )}
                                </tbody>
                            </table>
                        </div> :
                        (
                            <div className="w-full">
                                <div className="flex flex-col justify-center items-center bg-primary text-secondary p-6 rounded-none mx-6">
                                    <ExclamationTriangleIcon className='w-20 mb-4' />
                                    <Typography variant="lead" className="">
                                        {(date || codeSearch) ? "Vous n'avez pas un code avec ce filtre" : "Vous n'avez encore pas de codes !"}
                                    </Typography>
                                </div>
                            </div>
                        )}
                </CardBody>
                {promoCodes.length !== 0 && <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
                    <Button disabled={page === 1} onClick={() => setPage(page - 1)} variant="outlined" size="sm" className="bg-white text-primary capitalize rounded-none">
                        Précédent
                    </Button>
                    <div className="flex items-center gap-2">
                        {pageNumbers.map((pageNum) => (
                            <IconButton onClick={() => setPage(pageNum)} key={pageNum} variant={page === pageNum ? "outlined" : "text"} size="sm" className={`${page === pageNum && "border-white"} text-white rounded-none`}>
                                {pageNum}
                            </IconButton>
                        ))}
                    </div>
                    <Button disabled={page === numberOfPages} onClick={() => setPage(page + 1)} variant="filled" size="sm" className="bg-white text-primary capitalize rounded-none shadow-none">
                        Suivant
                    </Button>
                </CardFooter>}
            </Card>

            <AddCode open={openAddCode} handleOpen={handleOpenAddCode} setPromoCodes={setPromoCodes} />
            <DeleteCode open={openDeleteCode} handleOpen={handleOpenDeleteCode} setPromoCodes={setPromoCodes} selectedPromoCode={selectedPromoCode} setSelectedPromoCode={setSelectedPromoCode} />
        </div>
    )
}

export default PromoCodes